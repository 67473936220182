import React from 'react';
import PropTypes from 'prop-types';
import { Button, Group, Text, Table, HStack, Box } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { convertToCamelCase } from '../../../helpers';
import { get } from 'lodash';
import moment from 'moment';
import { DialogRoot, DialogContent, DialogBody, DialogFooter, DialogCloseTrigger } from '@/components/ui/dialog';

const ShowConnectedIncidents = ({ onCloseModal, recordNo, connectIncidents }) => {
  const tableCols = [
    {
      key: 'recordNo',
      label: 'Case ID',
    },
    {
      key: 'nonConformanceSubType[0].category',
      label: 'Category',
    },
    {
      key: 'status',
      label: 'status',
    },
    {
      key: 'entryDate',
      label: 'Date Raised',
    },
    {
      key: 'modified',
      label: 'Last Update',
    },
    {
      key: '',
      label: 'Action',
    },
  ];
  const incidents = get(connectIncidents, 'incidents', []);
  return (
    <DialogRoot centered open onClose={onCloseModal}>
      <DialogContent minWidth="1000px">
        {/* <DialogHeader borderBottomWidth="1px">
          <Heading fontSize="20px ">Incident {recordNo}</Heading>
        </DialogHeader> */}
        <DialogCloseTrigger asChild onClick={onCloseModal} />
        <DialogBody padding="25px 52px">
          <Box>
            <Table.Root variant="simple">
              <Table.Header>
                <Table.Row>
                  {tableCols.map(col => {
                    return (
                      <Table.ColumnHeader key={col.key} padding="12px 6px">
                        <HStack gap="3px">
                          <Text fontSize="12px">{col.label}</Text>
                        </HStack>
                      </Table.ColumnHeader>
                    );
                  })}
                </Table.Row>
              </Table.Header>
              <Table.Body backgroundColor="#ffffff" fontSize="12px">
                {incidents.map(col => {
                  return (
                    <Table.Row>
                      {tableCols.map((val, index) => {
                        let element = null;
                        if (['entryDate', 'modified'].includes(val.key)) {
                          let date = get(col, val.key, '-');
                          element = moment(date).isValid() ? moment(date).format('DD-MM-YYYY') : '-';
                        } else if (val.key !== '') {
                          element = get(col, val.key, '-');
                        } else {
                          element = (
                            <Link to={`/incidents/${get(col, 'recordNo', '')}`} onClick={onCloseModal}>
                              <Button
                                marginLeft="20px"
                                colorScheme="actionSecondary"
                                width="57px"
                                height="28px"
                                borderRadius="24px"
                              >
                                Open
                              </Button>
                            </Link>
                          );
                        }
                        return <Table.Cell key={index}>{element}</Table.Cell>;
                      })}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table.Root>
          </Box>
        </DialogBody>
        <DialogFooter borderTopWidth="1px">
          <Group colorScheme="actionPrimary" marginLeft="auto" fontSize="16px" fontWeight="900" gap="9px">
            <Button
              variant="outline"
              fontSize="inherit"
              fontWeight="inherit"
              height="40px"
              width="130px"
              onClick={onCloseModal}
            >
              Close
            </Button>
          </Group>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

ShowConnectedIncidents.propTypes = {
  recordNo: PropTypes.number,
  onCloseModal: PropTypes.func,
};

const mapStateToProps = state => {
  const result = {};
  return convertToCamelCase(result);
};

export default connect(mapStateToProps)(ShowConnectedIncidents);
