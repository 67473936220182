import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  IconButton,
  Text,
  VStack,
  Icon,
  Group,
  Stack,
} from '@chakra-ui/react';
import { ReactComponent as NewIncidentIconSVG } from '../../../assets/new-incident.svg';
import { ReactComponent as NewNCRIconSVG } from '../../../assets/new-ncr.svg';
import { ReactComponent as NewEntrapmentIconSVG } from '../../../assets/new_entrapment.svg';
import { ReactComponent as NewNotSureIconSVG } from '../../../assets/new-not-sure.svg';
import { PreambleText, TipCard } from '../Components/index';
import SelectField from '../../basic/SelectField';
import { ReactComponent as ArrowRightIconSVG } from '../../../assets/arrow-right.svg';
import { resetPurchaseOrders } from '../../../actions/actions_orders';
import { clearNCRErrorOrder, fetchNCROrder } from '../../../actions/action_ncrs';
import { IoAdd } from 'react-icons/io5';
import Constants from '../../../Constants';
import { Alert } from '@/components/ui/alert';
import { Radio, RadioGroup } from '@/components/ui/radio';
import { Field } from '@/components/ui/field';

const NewNCROption = ({ icon, label, value }) => (
  <Center
    width="210px"
    height="210px"
    borderWidth="1px"
    borderRadius="8px"
    flexDirection="column"
    backgroundColor="card.default"
  >
    {icon}
    <Heading fontSize="18px" fontWeight="500" marginTop="14px">
      {label}
    </Heading>
    <Radio value={value} marginTop="14px" />
  </Center>
);

NewNCROption.propTypes = {
  icon: PropTypes.elementType,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const StartButton = props => (
  <Button colorScheme="actionPrimary" width="108px" height="40px" fontWeight="700" {...props}>
    Start
    <ArrowRightIconSVG />
  </Button>
);

const QuestionnaireFormField = ({ value, label, options, onChange }) => (
  <Box>
    <Field label={label} labelProps={{ fontWeight: '700', color: 'actionSecondary.default' }}>
      <SelectField
        containerStyles={{
          width: '244px',
        }}
        value={value}
        options={options}
        onChange={onChange}
      />
    </Field>
  </Box>
);

QuestionnaireFormField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    decision: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      decision: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

const defaultQuestionnaireState = {
  type: null,
  raise: null,
  regarding: null,
  hasValue: null,
  completed: false,
  showVerdict: false,
  verdict: null,
};

const NewNCRStart = ({
  dispatch,
  onSubmit,
  onUpdate,
  addPurchaseOrder,
  handleTypeChange,
  form,
  packerOptions,
  token,
  orderHistory,
  hasExecutedSearchs,
  ncrs: { errorMessage, hasError },
  packerPlants,
}) => {
  const [questionnaire, setQuestionnaire] = useState(defaultQuestionnaireState);

  const ncrTypes = {
    ncr: {
      key: Constants.NCR_TYPES.NCR,
      label: 'NCR',
    },
    incident: {
      key: Constants.NCR_TYPES.INCIDENT,
      label: 'Incident',
    },
    entrapment: {
      key: Constants.NCR_TYPES.ENTRAPMENT,
      label: 'Entrapment',
    },
  };

  const handleEstablishmentChange = (e, index) => {
    onUpdate(questionnaire.type === Constants.NCR_TYPES.INCIDENT ? `packerPlantId-${index}` : 'packerPlantId', e.value);

    if (e.value) {
      const packer = packerOptions.find(opt => opt.packerPlantIds.includes(e.value));
      onUpdate(
        questionnaire.type === Constants.NCR_TYPES.INCIDENT ? `packerId-${index}` : 'packerId',
        packer ? packer.value : null
      );
    }
  };

  useEffect(() => {
    // reset on component lifecycle end
    return () => {
      dispatch(resetPurchaseOrders());
      onUpdate('error', '');
    };
  }, []);

  useEffect(() => {
    if (hasExecutedSearchs) {
      if (orderHistory.length > 0) {
        if (questionnaire.type === Constants.NCR_TYPES.NCR || questionnaire.type === Constants.NCR_TYPES.ENTRAPMENT) {
          onSubmit(ncrTypes[questionnaire.type], orderHistory[0]);
        } else {
          onSubmit(ncrTypes[questionnaire.type], orderHistory);
        }

        dispatch(resetPurchaseOrders());
      } else onUpdate('error', 'PO not found');
    }
  }, [hasExecutedSearchs]);

  const onUpdateQuestionnaire = (key, value) => {
    const update = {
      completed: key === 'type' && value !== 'not_sure',
      showVerdict: false,
      [key]: value,
    };

    if (key === 'type' && value !== Constants.NCR_TYPES.INCIDENT) {
      handleTypeChange();
    }
    // if (!update.completed) {
    //   update = { ...update, raise: null, regarding: null, hasValue: null };
    // }

    setQuestionnaire(prevState => ({
      ...prevState,
      ...update,
    }));
  };

  const onSubmitQuestionnaire = () => {
    const { raise, regarding, hasValue } = questionnaire;

    const answers = {
      raise,
      regarding,
      hasValue,
    };
    // const ncrDecisionCount = Object.values(answers).reduce((acc, curr) => acc + (curr.decision === 'ncr' ? 1 : 0), 0);
    const incidentDecisionCount = Object.values(answers).reduce(
      (acc, curr) => acc + (curr.decision === Constants.NCR_TYPES.INCIDENT ? 1 : 0),
      0
    );

    setQuestionnaire(prevState => ({
      ...prevState,
      verdict: incidentDecisionCount >= 2 ? Constants.NCR_TYPES.INCIDENT : Constants.NCR_TYPES.NCR,
      showVerdict: true,
    }));
  };

  const questionnaireConfig = [
    {
      key: 'raise',
      label: 'Do you want to raise a:',
      options: [
        { label: 'Single PO Claim', value: 'Single PO Claim', decision: Constants.NCR_TYPES.NCR },
        { label: 'Multi-packer PO Claim', value: 'Multi-packer PO Claim', decision: Constants.NCR_TYPES.INCIDENT },
      ],
    },
    {
      key: 'regarding',
      label: 'Is this claim regarding:',
      options: [
        { label: 'Multi-packer claim', value: 'Multi-packer claim', decision: Constants.NCR_TYPES.INCIDENT },
        { label: 'Quality', value: 'Quality', decision: Constants.NCR_TYPES.INCIDENT },
        {
          label: 'Entrapment (not plastic FM finding)',
          value: 'Entrapment (not plastic FM finding)',
          decision: Constants.NCR_TYPES.NCR,
        },
      ],
    },
    {
      key: 'hasValue',
      label: 'Does your claim have a value:',
      options: [
        { label: 'Yes', value: 'Yes', decision: Constants.NCR_TYPES.NCR },
        { label: 'No', value: 'No', decision: Constants.NCR_TYPES.INCIDENT },
      ],
    },
  ];

  const handleNewNCRStart = () => {
    dispatch(clearNCRErrorOrder());
    dispatch(resetPurchaseOrders());
    dispatch(fetchNCROrder(token, form.packerIdInternalPOs));
  };

  const renderErrorMessage = () => {
    return form.error || hasError ? (
      <Alert
        status="error"
        marginTop="23px"
        backgroundColor="#FED7D7"
        borderRadius="6px"
        fontWeight="400"
        fontSize="16px"
        title={form.error || errorMessage}
        closable
        onClose={() => {
          onUpdate('error', '');
          dispatch(clearNCRErrorOrder());
        }}
      />
    ) : (
      ''
    );
  };

  return (
    <Box>
      {questionnaire.showVerdict && questionnaire.verdict && (
        <Box marginTop="8px">
          <Text
            fontWeight="400"
            marginLeft={{
              base: '4px',
              lg: '0',
            }}
          >
            Looks like you are trying to make a:
          </Text>
          <Flex flexDirection="column" alignItems="center" marginTop="43px" position="relative">
            <Center
              width={{ base: '280px', lg: '404px' }}
              height="270px"
              borderWidth="1px"
              borderRadius="8px"
              flexDirection="column"
              backgroundColor="card.default"
            >
              <Box>
                <Icon width="132px" height="132px" viewBox="0 0 85 88" color="actionSecondary.default">
                  {questionnaire.verdict === Constants.NCR_TYPES.INCIDENT ? <NewIncidentIconSVG /> : <NewNCRIconSVG />}
                </Icon>
              </Box>
              <Heading fontSize="18px" fontWeight="700" marginTop="14px">
                New {ncrTypes[questionnaire.verdict].label}
              </Heading>
            </Center>
            <TipCard
              width="259px"
              position="absolute"
              right="90px"
              display={{
                base: 'none',
                lg: 'block',
              }}
            >
              {questionnaire.verdict === Constants.NCR_TYPES.INCIDENT ? (
                <Text>
                  A Quality Incident is when there are issues with product quality and food safety that happen
                  regularly.
                  <br /> These are raised for awareness. Whether an issue is identified upon delivery, discovered later,
                  or ultimately involves a customer, incidents need to be documented and reported, and then addressed
                  immediately before the problem become more widespread.
                  <br /> For example, multi-packer claims.
                </Text>
              ) : (
                <Text>
                  A Non-Conformance Report (NCR) is a document that identifies and reports any discrepancies between the
                  actual condition of a product, service or process and the requirements defined by quality standards.
                  <br />
                  The NCR reports the specific Non-Conformity found to understand the underlying root cause and enables
                  subsequent <u>actions</u> to be taken. <br /> Furthermore, the NCRs help to create a regulatory plan
                  of action to prevent it from happening again and assist future compliance
                </Text>
              )}
            </TipCard>
            <Group
              gap={{ base: '40px', lg: '70px' }}
              marginTop="46px"
              colorScheme="actionPrimary"
              fontSize="16px"
              fontWeight="600"
            >
              <Button
                variant="outline"
                height="42px"
                width={{
                  base: '120px',
                  lg: '166px',
                }}
                fontWeight="inherit"
                fontSize="inherit"
                onClick={() => {
                  setQuestionnaire(defaultQuestionnaireState);
                }}
              >
                Cancel
              </Button>
              <Button
                height="42px"
                width={{
                  base: '120px',
                  lg: '166px',
                }}
                fontWeight="inherit"
                fontSize="inherit"
                onClick={() => onUpdateQuestionnaire('type', questionnaire.verdict)}
              >
                Confirm
              </Button>
            </Group>
          </Flex>
        </Box>
      )}

      <Flex flexDirection="column" alignItems="center" marginY={{ base: '13px', lg: '84px' }}>
        <Box width={{ base: '328px', lg: 'auto' }}>
          {!questionnaire.showVerdict && (
            <RadioGroup
              value={questionnaire.type}
              colorScheme="actionPrimary"
              marginBottom="7px"
              onValueChange={e => onUpdateQuestionnaire('type', e.value)}
            >
              <Stack
                direction={{ base: 'column', lg: 'row' }}
                alignItems="center"
                borderBottomWidth="1px"
                paddingBottom={{ base: '33px', lg: '56px' }}
                gap={{ base: '36px', lg: '67px' }}
                color="actionSecondary.default"
              >
                <NewNCROption icon={<NewIncidentIconSVG />} label="New Incident" value="incident" />
                <NewNCROption icon={<NewNCRIconSVG />} label="New NCR" value="ncr" />
                <NewNCROption icon={<NewEntrapmentIconSVG />} label="New Entrapment" value="entrapment" />
                <NewNCROption icon={<NewNotSureIconSVG />} label="I'm not sure" value="not_sure" />
              </Stack>
            </RadioGroup>
          )}

          {questionnaire.type === 'not_sure' && !questionnaire.showVerdict && (
            <Flex marginTop="26px" flexDirection="column">
              <Box flexGrow={1}>
                <Text>
                  First, let&apos;s determine what type of claim you would like to do by answering a few simple
                  questions:
                </Text>
                <VStack gap="30px" align="stretch" marginTop="15px">
                  {questionnaireConfig.map(item => (
                    <QuestionnaireFormField
                      {...item}
                      value={questionnaire[item.key]}
                      onChange={e => onUpdateQuestionnaire(item.key, e)}
                    />
                  ))}
                </VStack>
              </Box>
              <StartButton
                marginTop={{ base: '51px', lg: '15px' }}
                marginLeft={{ base: '0', lg: 'auto' }}
                alignSelf={{ base: 'center' }}
                disabled={!(questionnaire.raise && questionnaire.regarding && questionnaire.hasValue)}
                onClick={onSubmitQuestionnaire}
              />
            </Flex>
          )}

          {questionnaire.completed && !questionnaire.showVerdict && (
            <Box
              marginTop={{ base: '21px', lg: '49px' }}
              marginX={{
                base: 'auto',
                lg: '0',
              }}
              width={{ base: 'fit-content', lg: '764px' }}
              display={{
                base: 'flex',
                lg: 'block',
              }}
              flexDir={{
                base: 'column',
                lg: 'unset',
              }}
            >
              <Flex
                justifyContent="space-between"
                flexDirection={{
                  base: 'column',
                  lg: 'row',
                }}
                alignItems={{ base: 'center', lg: 'end' }}
              >
                <Box>
                  {form.packerIdInternalPOs.map((packerIdInternalPO, index) => (
                    <Flex
                      key={packerIdInternalPO.packerId}
                      flexDirection={{
                        base: 'column',
                        lg: 'row',
                      }}
                      marginTop="14px"
                    >
                      <Box marginRight="10px">
                        <PreambleText>Establishment:</PreambleText>
                        <SelectField
                          placeholder="Select Establishment"
                          containerStyles={{
                            width: '212px',
                          }}
                          value={
                            packerPlants.filter(pp => pp.packerId === packerIdInternalPO.packerId).length > 0
                              ? packerPlants
                                  .filter(pp => pp.packerId === packerIdInternalPO.packerId)
                                  .map(el => ({
                                    value: el.id,
                                    label: el.name,
                                  }))
                                  .find(el => el.value === packerIdInternalPO.packerPlantId)
                              : null
                          }
                          options={
                            packerIdInternalPO.packerId
                              ? packerPlants
                                  .filter(pp => pp.packerId === packerIdInternalPO.packerId)
                                  .map(el => ({
                                    value: el.id,
                                    label: el.name,
                                  }))
                              : packerPlants.map(el => ({ value: el.id, label: el.name }))
                          }
                          onChange={e => handleEstablishmentChange(e, index)}
                          menuPortalTarget={document.body}
                        />
                      </Box>
                      <Box marginRight="10px">
                        <PreambleText>Packer:</PreambleText>
                        <SelectField
                          placeholder="Select Packer"
                          containerStyles={{
                            width: '212px',
                          }}
                          defaultValue={packerIdInternalPO.packerId || ''}
                          options={packerOptions}
                          // onChange={e => onUpdate('packerId', e.value)}
                          onChange={e =>
                            onUpdate(
                              questionnaire.type === Constants.NCR_TYPES.INCIDENT ? `packerId-${index}` : 'packerId',
                              e.value
                            )
                          }
                          menuPortalTarget={document.body}
                        />
                      </Box>

                      <Box marginRight="10px">
                        <PreambleText>PO#:</PreambleText>
                        <Input
                          placeholder="Enter PO#"
                          // marginLeft={{ base: '0', lg: '31px' }}
                          // marginTop={{ base: '9px', lg: '0' }}
                          // marginRight={{ base: '0', lg: '10px' }}
                          width="210px"
                          flexShrink={0}
                          value={packerIdInternalPO.internalPO || ''}
                          // onChange={e => onUpdate('internalPO', e.target.value)}
                          onChange={e =>
                            onUpdate(
                              questionnaire.type === Constants.NCR_TYPES.INCIDENT
                                ? `internalPO-${index}`
                                : 'internalPO',
                              e.target.value
                            )
                          }
                        />
                      </Box>
                      {questionnaire.type === Constants.NCR_TYPES.INCIDENT &&
                        index === form.packerIdInternalPOs.length - 1 && (
                          <IconButton
                            colorScheme="actionSecondary"
                            rounded="full"
                            onClick={addPurchaseOrder}
                            marginTop="auto"
                            marginRight="10px"
                          >
                            <IoAdd />
                          </IconButton>
                        )}
                    </Flex>
                  ))}
                </Box>
                <Box
                  display={{
                    base: 'block',
                    lg: 'none',
                  }}
                >
                  {renderErrorMessage()}
                </Box>
                <StartButton
                  marginTop={{ base: '21px', lg: '0' }}
                  onClick={handleNewNCRStart}
                  disabled={Boolean(
                    form.packerIdInternalPOs.find(line =>
                      Object.entries(line).some(l => l[0] !== 'error' && (l[1] === null || l[1] === ''))
                    )
                  )}
                />
              </Flex>
              <Box
                display={{
                  base: 'none',
                  lg: 'block',
                }}
              >
                {renderErrorMessage()}
              </Box>
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

NewNCRStart.propTypes = {
  dispatch: PropTypes.func,
  onSubmit: PropTypes.func,
  onUpdate: PropTypes.func,
  addPurchaseOrder: PropTypes.func,
  form: PropTypes.shape({
    packerIdInternalPOs: PropTypes.arrayOf(
      PropTypes.shape({
        packerId: PropTypes.string,
        packerPlantId: PropTypes.string,
        internalPO: PropTypes.string,
      })
    ),
    error: PropTypes.string,
  }),
  handleTypeChange: PropTypes.func,
  packerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  token: PropTypes.string,
  orderHistory: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  apiRequestError: PropTypes.string,
  hasExecutedSearchs: PropTypes.string,
  ncrs: PropTypes.shape({
    errorMessage: PropTypes.string,
    hasError: PropTypes.bool,
  }),
  packerPlants: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

export default React.memo(NewNCRStart);
