import React from 'react';
import PropTypes from 'prop-types';
import { Heading, HStack, Button, VStack, Box, Text } from '@chakra-ui/react';
import { ReactComponent as OverviewOutlineIconSVG } from '../../../assets/overview-outline.svg';
import { get } from 'lodash';
import { Link } from 'react-router';
import { PODetails, StatusPriorityBadge } from '../Components';
import { getOr } from '../../../helpers';
import {
  DrawerRoot,
  DrawerBackdrop,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseTrigger,
} from '@/components/ui/drawer';

const SectionCard = ({ children, title }) => {
  return (
    <Box backgroundColor="card.default" padding="16px 20px 34px">
      <Box borderBottomWidth="1px" paddingBottom="13px" marginBottom="16px">
        <Heading fontSize="16px">{title}</Heading>
      </Box>
      {children}
    </Box>
  );
};

SectionCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

const OverviewDrawer = props => {
  const { isOpen, onClose, overviewNCR } = props;

  const status = get(overviewNCR, 'status');
  const priority = get(overviewNCR, 'claim.priority', 'minor');

  return (
    <DrawerRoot open={isOpen} placement="end" closeOnOverlayClick={false}>
      <DrawerBackdrop />
      <DrawerContent minWidth="803px" paddingX="51px">
        <DrawerCloseTrigger onClick={onClose} />
        <DrawerHeader padding="37px 0 0 0">
          <HStack gap="10px" color="accent.one.default" borderBottomWidth="1px" paddingBottom="20px">
            <OverviewOutlineIconSVG />
            <Heading fontSize="16px" color="inherit">
              Overview
            </Heading>
            <Box marginLeft="auto !important">
              <Link to={`/ncr/${get(overviewNCR, 'recordNo')}`}>
                <Button backgroundColor="gray.100" height="30px" color="#000">
                  View NCR
                </Button>
              </Link>
            </Box>
          </HStack>
        </DrawerHeader>
        <DrawerBody overflowY="overlay" paddingX="0" paddingY="23px" fontWeight="400px">
          <HStack justifyContent="flex-end">
            {status ? <StatusPriorityBadge> {status.replace(/_/g, ' ')}</StatusPriorityBadge> : ''}
            {priority ? <StatusPriorityBadge> {priority.replace(/_/g, ' ')}</StatusPriorityBadge> : ''}
          </HStack>
          <VStack align="stretch" gap="23px" marginTop="25px">
            <SectionCard title="PO Details">
              <PODetails overviewNCR={overviewNCR} gap="34px" />
            </SectionCard>
            <SectionCard title="Details of Issue">
              <Text>{getOr(overviewNCR, 'ncrDetails.details', '-')}</Text>
            </SectionCard>
            <SectionCard title="Initial Investigation">
              <Text>{getOr(overviewNCR, 'ncrDetails.initialInvestigation.details', '-')}</Text>
            </SectionCard>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </DrawerRoot>
  );
};

OverviewDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  overviewNCR: PropTypes.shape({
    status: PropTypes.string,
    claim: PropTypes.shape({
      priority: PropTypes.string,
    }),
    recordNo: PropTypes.string,
    ncrDetails: PropTypes.shape({
      details: PropTypes.string,
      initialInvestigation: PropTypes.shape({
        details: PropTypes.string,
      }),
    }),
  }),
};

export default OverviewDrawer;
