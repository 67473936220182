import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Group, Center, HStack, Input, Spinner, Text, VStack, Flex } from '@chakra-ui/react';
import HorizontalNavigationBand from '../../core/HorizontalNavigationBand';
import { FaSearch } from 'react-icons/fa';
import NCRsStatusTile from './NCRsStatusTile';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import SelectField from '../../basic/SelectField';
import ClaimsListTable from './ClaimsListTable';
import IncidentClaimsListTable from './IncidentClaimsListTable';
import CommunicationDrawer from '../Forms/CommunicationDrawer';
import { Link, useLocation } from 'react-router';
import { connect } from 'react-redux';
import { fetchEntitiesData, findEntitiesInState } from '../../../slices/masterData/entityManagerSlice';
import { convertToCamelCase, getOr } from '../../../helpers';
import { fetchNCRSummary, resetNCRSearch, searchGrinderNCRs } from '../../../actions/action_ncrs';
import { cloneDeep, get, isEmpty } from 'lodash';
import { checkIfIsAdminUser, checkIfIsGrinderUser } from '../../../utils';
import DeleteNCRModal from '../Components/DeleteNCRModal';
import OverviewDrawer from '../Forms/OverviewDrawer';
import { ReactComponent as CalendarIconSVG } from '../../../assets/calender.svg';
import DatePicker from '../../core/DatePicker';
import { PaginationBar } from '../../core/PaginationComponents';
import moment from 'moment';
import ReleaseToPackerNCR from '../Components/ReleaseToPackerNCR';
import Constants from '../../../Constants';
import { ReactComponent as ShareOutlineSVG } from '../../../assets/share-outline.svg';
import axios from 'axios';
import { InputGroup } from '../../ui/input-group';
import withRouter from '@/components/utilities/withRouter';
import { Checkbox } from '@/components/ui/checkbox';

const QuickFilterButton = ({ children, ...rest }) => {
  return (
    <Button
      backgroundColor="quickFilter.bgColor"
      color="quickFilter.color"
      borderColor="quickFilter.borderColor"
      height="28px"
      padding="4px 8px"
      fontSize="16px"
      fontWeight="400"
      {...rest}
    >
      {children}
    </Button>
  );
};

QuickFilterButton.propTypes = {
  children: PropTypes.node.isRequired,
};

const CheckboxComponent = ({ label, ...rest }) => {
  return (
    <Box minW="84px">
      <Checkbox colorScheme="actionSecondary" marginBottom="0" {...rest}>
        {label}
      </Checkbox>
    </Box>
  );
};
CheckboxComponent.propTypes = {
  label: PropTypes.string.isRequired,
};

const FilterGroup = ({ children, label }) => {
  return (
    <Flex gap="12px" align="center">
      <Box width="124px">
        <Text fontSize="15px" fontWeight="400">
          {label}:
        </Text>
      </Box>
      {children}
    </Flex>
  );
};
FilterGroup.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};

const CLEAN_FILTERS = {
  claim_type: [],
  ncr_status: [],
  final_outcome: [],
  ncr_category: [],
  ncr_start_date: '',
  ncr_end_date: '',
  incident_status: [],
  priority: [],
  claim_value_aud: [],
  internal_po_number: '',
  record_no: '',
  page: 1,
  limit: 20,
  string: '',
  grinders: [],
  packers: [],
};

const arrayFilters = ['claim_type', 'ncr_status', 'final_outcome', 'incident_status', 'priority', 'claim_value_aud'];
let globalFilter;
const DateInputRightElement = () => {
  return (
    // <InputRightElement>
    <CalendarIconSVG />
    // </InputRightElement>
  );
};

const getSearchNCRFiltersFromUrl = (urlSearch, nonConformanceTypesOptions, grindersOptions, packersOptions) => {
  const searchParams = new URLSearchParams(urlSearch);
  const filters = {};
  searchParams.forEach((value, key) => {
    let searchValue = value;
    if (arrayFilters.includes(key) || key === 'ncr_category') {
      searchValue = value.split(',');
    }
    filters[key] = searchValue;
  });

  if (filters.ncr_category && Array.isArray(filters.ncr_category)) {
    filters.ncr_category = nonConformanceTypesOptions.filter(nonConformanceTypesOption =>
      filters.ncr_category.includes(nonConformanceTypesOption.value)
    );
  }

  if (filters.grinders && Array.isArray(filters.grinders)) {
    filters.grinders = grindersOptions?.filter(grinderOption => filters.grinders.includes(grinderOption.uid));
  }

  if (filters.packers && Array.isArray(filters.packers)) {
    filters.packers = packersOptions?.filter(packerOption => filters.packers.includes(packerOption.value));
  }
  return filters;
};

const FilterSection = ({
  instantSearchNCRs,
  handleFilterChange,
  filterState,
  setFilterState,
  nonConformanceTypesOptions,
  selectDate,
  resetSearch,
  handleSearch,
  isSearching,
  grindersOptions = [],
  packersOptions = [],
}) => {
  const { search } = useLocation();
  const dateRaisedStartRef = useRef();
  const dateRaisedEndRef = useRef();

  useEffect(() => {
    const filters = getSearchNCRFiltersFromUrl(search, nonConformanceTypesOptions);
    setFilterState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        ...filters,
      },
    }));
    if (!isEmpty(filters)) {
      instantSearchNCRs(filters);
    }
  }, []);

  return (
    <>
      <Box gap="17px" paddingLeft="23px" maxWidth="899px">
        <HStack gap="17px">
          <Text fontWeight="700" fontSize="15px">
            Quick Filters:
          </Text>
          <Group variant="outline" gap="18px">
            <QuickFilterButton onClick={instantSearchNCRs}>All</QuickFilterButton>
            <QuickFilterButton
              onClick={() =>
                handleFilterChange('ncr_start_date', moment().subtract(24, 'hours').format('YYYY-MM-DD'), true)
              }
            >
              Created Last 24 Hours
            </QuickFilterButton>
            <QuickFilterButton
              onClick={() =>
                handleFilterChange('ncr_start_date', moment().subtract(1, 'weeks').format('YYYY-MM-DD'), true)
              }
            >
              Past week
            </QuickFilterButton>
            <QuickFilterButton
              onClick={() =>
                handleFilterChange('ncr_start_date', moment().subtract(1, 'months').format('YYYY-MM-DD'), true)
              }
            >
              Past Month
            </QuickFilterButton>
          </Group>
        </HStack>
        <HStack gap="17px" marginTop="13px">
          <Text fontWeight="700" fontSize="15px" alignSelf="flex-start" marginTop="7px">
            Multi Filters:
          </Text>
          <VStack gap="17px" align="stretch">
            <FilterGroup label="Date Raised">
              <Box width="125px">
                <DatePicker
                  ref={dateRaisedStartRef}
                  key="start_date"
                  id="start_date"
                  value={filterState.filters.ncr_start_date}
                  onChange={e => selectDate(e, 'ncr_start_date')}
                  inputProps={{
                    placeholder: 'Start',
                    borderColor: 'inherit',
                    inputRightElement: <DateInputRightElement />,
                  }}
                />
              </Box>
              <Box width="125px">
                <DatePicker
                  ref={dateRaisedEndRef}
                  key="end_date"
                  id="end_date"
                  value={filterState.filters.ncr_end_date}
                  onChange={e => selectDate(e, 'ncr_end_date')}
                  inputProps={{
                    placeholder: 'End',
                    borderColor: 'inherit',
                    inputRightElement: <DateInputRightElement />,
                  }}
                />
              </Box>
            </FilterGroup>
            <FilterGroup label="Claim Type">
              <CheckboxComponent
                label="NCR"
                onChange={() => handleFilterChange('claim_type', 'non_conformance')}
                checked={filterState.filters.claim_type.indexOf('non_conformance') > -1}
              />
              <CheckboxComponent
                label="Incident"
                onChange={() => handleFilterChange('claim_type', Constants.NCR_TYPES.INCIDENT)}
                checked={filterState.filters.claim_type.indexOf(Constants.NCR_TYPES.INCIDENT) > -1}
              />
            </FilterGroup>
            <FilterGroup label="NCR Status">
              <CheckboxComponent
                label="Raised"
                onChange={() => handleFilterChange('ncr_status', 'raised')}
                checked={filterState.filters.ncr_status.indexOf('raised') > -1}
              />
              <CheckboxComponent
                label="Open"
                onChange={() => handleFilterChange('ncr_status', 'open')}
                checked={filterState.filters.ncr_status.indexOf('open') > -1}
              />
              <CheckboxComponent
                label="Under Review"
                onChange={() => handleFilterChange('ncr_status', 'under_review')}
                checked={filterState.filters.ncr_status.indexOf('under_review') > -1}
              />
              <CheckboxComponent
                label="In Progress"
                onChange={() => handleFilterChange('ncr_status', 'in_progress')}
                checked={filterState.filters.ncr_status.indexOf('in_progress') > -1}
              />
              <CheckboxComponent
                label="Rejected"
                onChange={() => handleFilterChange('ncr_status', 'rejected')}
                checked={filterState.filters.ncr_status.indexOf('rejected') > -1}
              />
              <CheckboxComponent
                label="Closed"
                onChange={() => handleFilterChange('ncr_status', 'closed')}
                checked={filterState.filters.ncr_status.indexOf('closed') > -1}
              />
            </FilterGroup>
            <FilterGroup label="Incident Status">
              <CheckboxComponent
                label="Raised"
                onChange={() => handleFilterChange('incident_status', 'raised')}
                checked={filterState.filters.incident_status.indexOf('raised') > -1}
              />
              <CheckboxComponent
                label="Closed"
                onChange={() => handleFilterChange('incident_status', 'closed')}
                checked={filterState.filters.incident_status.indexOf('closed') > -1}
              />
            </FilterGroup>
            <FilterGroup label="NCR Type">
              <CheckboxComponent
                label="Minor (no value)"
                onChange={() => handleFilterChange('priority', 'minor')}
                checked={filterState.filters.priority.indexOf('minor') > -1}
              />
              <CheckboxComponent
                label="Major (<$2,000)"
                onChange={() => handleFilterChange('priority', 'major')}
                checked={filterState.filters.priority.indexOf('major') > -1}
              />
              <CheckboxComponent
                label="Serious (>$2,000)"
                onChange={() => handleFilterChange('priority', 'serious')}
                checked={filterState.filters.priority.indexOf('serious') > -1}
              />
            </FilterGroup>
            <FilterGroup label="NCR Category">
              <Box width="264px">
                <SelectField
                  styles={{
                    control: styles => ({
                      ...styles,
                      minHeight: '40px',
                    }),
                  }}
                  isClearable
                  isMulti
                  placeholder="Select Category"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={filterState.filters.ncr_category}
                  options={nonConformanceTypesOptions}
                  onChange={e => handleFilterChange('ncr_category', e)}
                  menuPortalTarget={document.body}
                />
              </Box>
            </FilterGroup>
            <FilterGroup label="Grinder">
              <Box width="264px">
                <SelectField
                  styles={{
                    control: styles => ({
                      ...styles,
                      minHeight: '40px',
                    }),
                  }}
                  isClearable
                  isMulti
                  placeholder="Select Grinder"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={filterState.filters.grinders}
                  options={grindersOptions}
                  onChange={e => handleFilterChange('grinders', e)}
                  menuPortalTarget={document.body}
                />
              </Box>
            </FilterGroup>
            <FilterGroup label="Packer">
              <Box width="264px">
                <SelectField
                  styles={{
                    control: styles => ({
                      ...styles,
                      minHeight: '40px',
                    }),
                  }}
                  isClearable
                  isMulti
                  placeholder="Select Packer"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={filterState.filters.packers}
                  options={packersOptions}
                  onChange={e => handleFilterChange('packers', e)}
                  menuPortalTarget={document.body}
                />
              </Box>
            </FilterGroup>
          </VStack>
        </HStack>
      </Box>
      <Group
        colorScheme="actionPrimary"
        marginLeft="auto !important"
        marginTop="-21px !important"
        gap="15px"
        fontSize="16px"
        height="43px"
      >
        <Button
          variant="ghost"
          fontSize="inherit"
          height="inherit"
          onClick={() => {
            if (typeof resetSearch === 'function') resetSearch();
            dateRaisedStartRef.current.setState({ inputValue: '' });
            dateRaisedEndRef.current.setState({ inputValue: '' });
          }}
        >
          Reset
        </Button>
        <Button
          fontSize="inherit"
          height="inherit"
          width="166px"
          onClick={handleSearch}
          loading={isSearching}
          loadingText="Searching..."
          disabled={isSearching}
        >
          Submit Search
        </Button>
      </Group>
    </>
  );
};

FilterSection.propTypes = {
  selectDate: PropTypes.func,
  instantSearchNCRs: PropTypes.func,
  handleFilterChange: PropTypes.func,
  filterState: PropTypes.shape({
    filters: PropTypes.shape({
      claim_type: PropTypes.arrayOf(PropTypes.string),
      ncr_status: PropTypes.arrayOf(PropTypes.string),
      final_outcome: PropTypes.arrayOf(PropTypes.string),
      ncr_category: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
      ncr_start_date: PropTypes.string,
      ncr_end_date: PropTypes.string,
      incident_status: PropTypes.arrayOf(PropTypes.string),
      priority: PropTypes.arrayOf(PropTypes.string),
      claim_value_aud: PropTypes.arrayOf(PropTypes.string),
      internal_po_number: PropTypes.string,
      record_no: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      string: PropTypes.string,
      grinders: PropTypes.arrayOf(
        PropTypes.shape({
          uid: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })
      ),
      packers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })
      ),
    }).isRequired,
  }).isRequired,
  setFilterState: PropTypes.func,
  nonConformanceTypesOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  resetSearch: PropTypes.func,
  handleSearch: PropTypes.func,
  isSearching: PropTypes.bool,
  grindersOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  packersOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const NCRs = props => {
  const {
    dispatch,
    ncrs: { refreshSearch, isSearching, hasError, searchResult, pagination, summary },
    nonConformanceTypesOptions,
    grindersOptions,
    packersOptions,
    user,
    token,
  } = props;
  const [isCommDrawerOpen, setIsCommDrawerOpen] = useState(false);

  const onOpenCommDrawer = () => setIsCommDrawerOpen(true);
  const onCloseCommDrawer = () => setIsCommDrawerOpen(false);

  const [isOverviewDrawerOpen, setIsOverviewDrawerOpen] = useState(false);

  const onOpenOverviewDrawer = () => setIsOverviewDrawerOpen(true);
  const onCloseOverviewDrawer = () => setIsOverviewDrawerOpen(false);

  const { navigate, location } = props;
  const { pathname, search } = location;

  const [state, setState] = useState({
    filters: CLEAN_FILTERS,
  });
  const [deleteNCRRecordNo, setDeleteNCRRecordNo] = useState(null);
  const [releaseToPackerNo, setReleaseToPackerNo] = useState(null);
  const [communicationNCRRecordNo, setCommunicationNCRRecordNo] = useState(null);
  const [communicationNCR, setCommunicationNCR] = useState(null);
  const [overviewNCR, setOverviewNCR] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  // const [searchQueryTemp, setSearchQueryTemp] = useState(null);
  const [showIncidents, setShowIncidents] = useState(false);

  const updateURLSearchParams = updateFilters => {
    const cleanedUpFilters = Object.entries(updateFilters).reduce((acc, [key, value]) => {
      let filterValue = value;
      if (Array.isArray(filterValue)) {
        filterValue = filterValue.map(option => getOr(option, 'value', option));
      }
      if (filterValue)
        return {
          ...acc,
          ...(filterValue && !isEmpty(filterValue) ? { [key]: getOr(filterValue, 'value', filterValue) } : {}),
        };
      return acc;
    }, {});
    const updatedQueryString = new URLSearchParams(cleanedUpFilters).toString();
    navigate(`${pathname}?${updatedQueryString}`);
  };

  const handleSearch = searchFilters => {
    const filters = searchFilters && searchFilters.clientX ? state.filters : searchFilters || state.filters;
    globalFilter = filters;
    dispatch(searchGrinderNCRs(filters, token));
    updateURLSearchParams(filters);
    if (
      filters.claim_type &&
      filters.claim_type.length === 1 &&
      filters.claim_type[0] === Constants.NCR_TYPES.INCIDENT
    ) {
      setShowIncidents(true);
    } else {
      setShowIncidents(false);
    }
  };

  useEffect(() => {
    if (searchQuery && !isSearching) {
      handleSearch({ string: searchQuery });
    }
  }, [searchQuery]);

  useEffect(() => {
    dispatch(fetchNCRSummary(token));
  }, []);

  useEffect(() => {
    if (!hasError && refreshSearch) {
      const filters = getSearchNCRFiltersFromUrl(search, nonConformanceTypesOptions);
      globalFilter = filters;
      handleSearch(filters);
    }
  }, [hasError, refreshSearch]);

  useEffect(() => {
    dispatch(fetchEntitiesData(['claim_entity', 'non_conformance_type', 'non_conformance_sub_type']));
    dispatch(fetchEntitiesData());
  }, []);

  const instantSearchNCRs = searchConfig => {
    // Clears everything and then sets a simple one-thing search
    const updateFilters = {
      ...CLEAN_FILTERS,
      ...(searchConfig && !searchConfig.clientX && searchConfig),
    };
    globalFilter = updateFilters;
    handleSearch(updateFilters);

    setState(prevState => ({
      ...prevState,
      filters: updateFilters,
    }));
  };

  const updateFilter = (filterKey, filterValue) => {
    setState(prevState => ({
      filters: {
        ...prevState.filters,
        [filterKey]: filterValue,
      },
    }));
  };

  const handlePageChange = filterConfig => {
    instantSearchNCRs({
      ...state.filters,
      ...filterConfig,
    });
  };

  const handleFilterChange = (key, value, instantSearch) => {
    const booleanFilters = [];

    if (instantSearch) {
      return instantSearchNCRs({ [key]: value });
    }
    if (value && value !== 'null') {
      const currentValue = get(state, `filters.${key}`);

      // These two keys can have multiple values, and are array types
      if (arrayFilters.includes(key)) {
        let updatedArray = [];
        // If status already has existing value, then remove it
        if (currentValue.includes(value)) {
          updatedArray = currentValue.filter(item => item !== value);
        } else {
          // Otherwise add it to an array
          updatedArray = [...currentValue, value];
        }
        return updateFilter(key, updatedArray);
      }
      // Otherwise, just add straight to object for checkbox fields in 'Other' category
      if (booleanFilters.includes(key)) {
        return updateFilter(key, !currentValue);
      }
      return updateFilter(key, value);
    }
    // If is null or undefined, then change value to blank
    return updateFilter(key, '');
  };

  const selectDate = (e, name, ref) => {
    // eslint-disable-next-line no-underscore-dangle
    handleFilterChange(name, e._isAMomentObject ? e.format('YYYY-MM-DD') : e);

    if (ref) {
      ref.focus();
    }
  };

  const resetSearch = e => {
    if (e) {
      e.preventDefault();
    }

    const newState = JSON.parse(JSON.stringify(cloneDeep(state)));
    newState.filters = CLEAN_FILTERS;
    updateURLSearchParams(newState.filters);
    setState(newState);
    setSearchQuery('');
    // setSearchQueryTemp('');
    dispatch(resetNCRSearch());
  };

  const isAdminUser = checkIfIsAdminUser(user);
  const isGrinderUser = checkIfIsGrinderUser(user);

  const handleOpenOverview = data => {
    setOverviewNCR(data);
    onOpenOverviewDrawer();
  };

  const handleOpenCommunicationDrawer = recordNo => {
    setCommunicationNCRRecordNo(recordNo);
    const ncr = searchResult.find(el => el.recordNo === recordNo);
    setCommunicationNCR(ncr);
    onOpenCommDrawer();
  };

  const handleCloseCommunicationDrawer = () => {
    setCommunicationNCRRecordNo(null);
    setCommunicationNCR(null);
    onCloseCommDrawer();
    handleSearch(globalFilter);
  };

  const [loading, setLoading] = useState(false);

  const informUserAboutError = errorMessage => {
    // eslint-disable-next-line no-alert
    alert(errorMessage);
  };

  const exportNCRs = () => {
    setLoading(true);
    // Extract values from globalFilter with default values
    const {
      claim_type = [],
      ncr_status = [],
      limit,
      page,
      priority = [],
      ncr_start_date = '',
      ncr_end_date = '',
      string = '',
      incident_status = [],
      ncr_category = [],
      grinders = [],
      packers = [],
    } = globalFilter;

    const ncrCategoryValues = ncr_category.length > 0 ? ncr_category.map(category => category.name).join(',') : '';
    const grindersValues = grinders.length > 0 ? grinders.map(grinder => grinder.uid).join(',') : '';
    const packersValues = packers.length > 0 ? packers.map(packer => packer.id).join(',') : '';
    axios
      .get(`${Constants.URL}export_ncrs`, {
        params: {
          claim_type: claim_type.length > 0 ? claim_type.join(',') : 'none',
          ncr_status: ncr_status.length > 0 ? ncr_status.join(',') : '',
          limit,
          page,
          priority: priority.length > 0 ? priority.join(',') : '',
          ncr_start_date,
          ncr_end_date,
          string,
          incident_status: incident_status.length > 0 ? incident_status.join(',') : '',
          ncr_category: ncrCategoryValues,
          ...(grindersValues && { grinders: grindersValues }),
          ...(packersValues && { packers: packersValues }),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      })
      .then(response => {
        const file = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const fileURL = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = 'NCRs.xlsx';
        document.body.appendChild(a);
        // Trigger the download
        a.click();
        // Clean up
        document.body.removeChild(a);
        setLoading(false);
      })
      .catch(() => {
        informUserAboutError('An error occurred during export. Please try again later.');
        setLoading(false);
      });
  };

  return (
    <Box paddingBottom="30px">
      <HorizontalNavigationBand justifyContent="space-between">
        <InputGroup width="264px" marginLeft="73px" endElement={<FaSearch color="gray.400" />}>
          <Input
            placeholder="Search by PO# or NCR#"
            fontWeight="700"
            value={state?.filters?.string}
            onChange={e => {
              if (!isSearching) {
                // setSearchQueryTemp(e.target.value);
                updateFilter('string', e.target.value);
              } else {
                // setSearchQueryTemp('')
                updateFilter('string', '');
              }
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setSearchQuery(state?.filters?.string);
              } else {
                setSearchQuery('');
              }
            }}
          />
        </InputGroup>
        {(isAdminUser || isGrinderUser) && (
          <Link to="/author-ncr">
            <Button colorScheme="actionSecondary" width="103px" height="48px" borderRadius="8px">
              New NCR
            </Button>
          </Link>
        )}
      </HorizontalNavigationBand>
      <Box maxW="1232px" marginLeft="auto" marginRight="auto" paddingBottom="40px">
        <NCRsStatusTile summary={summary || {}} handleFilterChange={handleFilterChange} />
        <DashboardSectionTile title="Search Filters" marginTop="31px" padding="20px 40px 27px 20px">
          {isEmpty(nonConformanceTypesOptions) ? (
            <Center height="425px">
              <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="actionSecondary.default" size="xl" />
            </Center>
          ) : (
            <FilterSection
              {...{
                instantSearchNCRs,
                handleFilterChange,
                filterState: state,
                setFilterState: setState,
                nonConformanceTypesOptions,
                grindersOptions,
                packersOptions,
                selectDate,
                resetSearch,
                handleSearch,
                isSearching,
                history,
                loading,
              }}
            />
          )}
        </DashboardSectionTile>

        {searchResult.length > 0 && (
          <>
            <DashboardSectionTile title="Claims List" marginY="34px" padding="20px 40px 27px 20px">
              {showIncidents ? (
                <IncidentClaimsListTable onClickDelete={recordNo => setDeleteNCRRecordNo(recordNo)} {...props} />
              ) : (
                <ClaimsListTable
                  onOpenCommunicationDrawer={recordNo => handleOpenCommunicationDrawer(recordNo)}
                  onOpenOverviewDrawer={data => handleOpenOverview(data)}
                  onClickDelete={recordNo => setDeleteNCRRecordNo(recordNo)}
                  onReleaseToPacker={recordNo => setReleaseToPackerNo(recordNo)}
                  {...props}
                />
              )}
            </DashboardSectionTile>
            <Button
              width="207px"
              height="43px"
              disabled={(showIncidents ? IncidentClaimsListTable.length : ClaimsListTable.length) < 1}
              onClick={exportNCRs}
              loadingText="Exporting..."
              background="secondary.50"
            >
              <Text as="p" fontSize="md" color="#333">
                Export Listed NCRs
              </Text>
              <ShareOutlineSVG />
            </Button>
            <PaginationBar
              currentPage={pagination.hasNext ? pagination?.nextPage - 1 || 1 : pagination?.pages}
              pages={pagination?.pages || 10}
              onPageChange={page => {
                handlePageChange({
                  page,
                });
              }}
              justifyContent="flex-end"
              marginY={6}
              paddingX={5}
            />
          </>
        )}
      </Box>
      <CommunicationDrawer
        {...{
          isOpen: isCommDrawerOpen,
          onOpen: onOpenCommDrawer,
          onClose: handleCloseCommunicationDrawer,
          user,
          mainView: 'ncr_home',
          chatListVisible: true,
          recordNo: communicationNCRRecordNo,
          ncr: communicationNCR,
        }}
      />
      <OverviewDrawer
        {...{
          isOpen: isOverviewDrawerOpen,
          onOpen: onOpenOverviewDrawer,
          onClose: onCloseOverviewDrawer,
          overviewNCR,
        }}
      />
      {deleteNCRRecordNo ? (
        <DeleteNCRModal
          onCloseModal={() => setDeleteNCRRecordNo(null)}
          recordNo={deleteNCRRecordNo}
          handleSearch={handleSearch}
        />
      ) : (
        ''
      )}
      {releaseToPackerNo && (
        <ReleaseToPackerNCR
          onCloseModal={() => setReleaseToPackerNo(null)}
          record_no={releaseToPackerNo}
          release_to_packer
          handleSearch={handleSearch}
        />
      )}
    </Box>
  );
};

NCRs.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  ncrs: PropTypes.shape({
    refreshSearch: PropTypes.bool,
    isSearching: PropTypes.bool,
    hasError: PropTypes.bool,
    searchResult: PropTypes.arrayOf(PropTypes.shape({})),
    pagination: PropTypes.shape({
      hasNext: PropTypes.bool,
      nextPage: PropTypes.number,
      pages: PropTypes.number,
    }),
    summary: PropTypes.shape({
      total: PropTypes.number,
      open: PropTypes.number,
      closed: PropTypes.number,
      inProgress: PropTypes.number,
    }),
  }),
  user: PropTypes.shape({
    token: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    // Add other user properties here
  }).isRequired,
  nonConformanceTypesOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  navigate: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  grindersOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  packersOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const mapStateToProps = state => {
  const result = {
    ...findEntitiesInState(state, {
      grinders: 'grinder',
      packer_plants: 'packer_plant',
      packers: 'packer',
      input_products: 'input_product',
      cold_stores: 'coldstore',
      transporters: 'transporter',
      claim_entities: 'claim_entity',
      non_conformance_types: 'non_conformance_type',
      non_conformance_sub_types: 'non_conformance_sub_type',
      units_of_measure: 'unit',
    }),
    user: state.user,
    token: state.user.token,
    apiRequestError: state.error,
    order_history: state.orders.order_history,
    hasExecutedSearchs: state.orders.hasExecutedSearchs,
    ncrs: state.ncrs,
  };
  const cameled = convertToCamelCase(result);

  const grindersOptions = cameled.grinders.map(grinder => ({
    ...grinder,
    value: grinder.uid,
    label: grinder.name,
  }));

  const packersOptions = cameled.packers.map(packer => ({
    ...packer,
    value: packer.id,
    label: packer.name,
  }));

  const nonConformanceSubTypesOptions = cameled.nonConformanceSubTypes.map(subType => ({
    ...subType,
    value: subType.id,
    label: subType.name,
  }));

  return {
    ...cameled,
    unitsOfMeasureOptions: cameled.unitsOfMeasure.map(unitOfMeasure => ({
      ...unitOfMeasure,
      value: unitOfMeasure.id,
      label: unitOfMeasure.name,
    })),
    nonConformanceTypesOptions: cameled.nonConformanceTypes.map(type => ({
      ...type,
      value: type.name,
      label: type.name,
    })),
    nonConformanceSubTypesOptions,
    grindersOptions,
    packersOptions,
  };
};

export default connect(mapStateToProps)(withRouter(NCRs));
