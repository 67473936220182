import Blob from 'blob';
import { saveAs } from 'file-saver';
import moment from 'moment';
import React, { Component } from 'react';
import Datetime from '@/components/core/DatePicker';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import _ from 'underscore';
import { fetchGrinders } from '../../actions/actions_grinders';
import { fetchBeefInputProducts } from '../../actions/actions_input_products';
import { fetchOptimizerData } from '../../actions/actions_optimizer';
import { clearOrders, createOrder, sendOrder } from '../../actions/actions_optimizer_bsm_orders';
import { fetchBeefOutputProducts } from '../../actions/actions_output_products';
import { fetchPackerPlants } from '../../actions/actions_packer_plants';
import Constants from '../../Constants';

function addCommas(nStr) {
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
}

// Functions to help with excel export
function datenum(v, date1904) {
  if (date1904) v += 1462;
  const epoch = Date.parse(v);
  return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

function Workbook() {
  if (!(this instanceof Workbook)) return new Workbook();
  this.SheetNames = [];
  this.Sheets = {};
}

function sheetFromArrayOfArrays(data, opts) {
  const ws = {};
  const range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
  for (let R = 0; R != data.length; ++R) {
    for (let C = 0; C != data[R].length; ++C) {
      if (range.s.r > R) range.s.r = R;
      if (range.s.c > C) range.s.c = C;
      if (range.e.r < R) range.e.r = R;
      if (range.e.c < C) range.e.c = C;
      const cell = { v: data[R][C] };
      if (cell.v == null) continue;
      const cell_ref = window.XLSX.utils.encode_cell({ c: C, r: R });

      if (typeof cell.v === 'number') cell.t = 'n';
      else if (typeof cell.v === 'boolean') cell.t = 'b';
      else if (cell.v instanceof Date) {
        cell.t = 'n';
        cell.z = window.XLSX.SSF._table[14];
        cell.v = datenum(cell.v);
      } else cell.t = 's';

      ws[cell_ref] = cell;
    }
  }
  if (range.s.c < 10000000) ws['!ref'] = window.XLSX.utils.encode_range(range);
  return ws;
}

function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

function checkForErrors(searchArea) {
  if (Object.keys(searchArea).length) {
    if (typeof searchArea.plant_id === 'undefined') {
      return 'Please make sure you have filled necessary fields.';
    }
    if (searchArea.output_products === '') {
      return 'Please make sure you have selected an output product.';
    }
    if (typeof searchArea.quantity === 'undefined' || searchArea.quantity === 0 || searchArea.quantity === '0') {
      return 'Please make sure you have filled in a quantity.';
    }
    if (typeof searchArea.production_date === 'undefined') {
      return 'Please make sure you have filled necessary fields.';
    }
    if (typeof searchArea.expected_yield === 'undefined') {
      return 'Please make sure you have filled necessary fields.';
    }
    if (searchArea.expected_yield.indexOf('%') === -1) {
      return 'Please check the expected yield. It should be a percentage.';
    }
    return false;
  }
  return false;
}

// This is the default set of form options given to the user
const searchTerms = [
  {
    slug: 'uid',
    type: 'string',
    enabled: false,
    title: 'Name',
  },
  {
    slug: 'plant_id',
    type: 'select',
    options: [],
    enabled: true,
    title: 'Grinder',
  },
  {
    slug: 'production_date',
    type: 'datepicker',
    enabled: true,
    title: 'Production Date',
  },
  {
    slug: 'output_products',
    type: 'select',
    options: [],
    enabled: true,
    title: 'Beef Output Product',
  },
  {
    slug: 'quantity',
    type: 'number',
    enabled: true,
    title: 'Quantity (lbs)',
  },
  {
    slug: 'expected_yield',
    type: 'string',
    enabled: true,
    title: 'Expected Yield',
  },
  {
    slug: 'cl',
    type: 'number',
    enabled: false,
    title: 'Blended Chemical Lean',
  },
  {
    slug: 'claim',
    type: 'number',
    enabled: false,
    title: 'Claim',
  },
  {
    slug: 'min_fresh',
    type: 'slider',
    enabled: true,
    title: 'Minimum % Fresh Beef',
  },
  {
    slug: 'min_50s',
    type: 'number',
    enabled: false,
    title: 'Minimum % 50CL Beef',
  },
];

// Placeholder data
function getSampleData(thisTerm) {
  switch (thisTerm) {
    case 'Name':
      return 'Classic Hamburger';
    case 'Grinder':
      return 1;
    case 'Beef Output Product':
      return '';
    case 'Quantity (lbs)':
      return '0';
    case 'Expected Yield':
      return '90%';
    case 'Blended Chemical Lean':
      return '75';
    case 'Claim':
      return null;
    case 'Minimum % Fresh Beef':
      return 0;
    case 'Minimum % 50CL Beef':
      return '0%';
    case 'Production Date':
      return moment().add(14, 'days').format('YYYY-MM-DD');
    default:
      return '';
  }
}

class Optimizer extends Component {
  constructor(props) {
    super(props);

    // Set up default interface
    const defaultValues = {};
    if (this.props.optimizer_beef_forecast) {
      let output_product_name = false;
      this.props.output_products.forEach(
        function (output_product) {
          if (output_product.uid === this.props.optimizer_beef_forecast.forecast.output_product_uid) {
            output_product_name = output_product;
          }
        }.bind(this)
      );
      defaultValues.uid = output_product_name.name;
      defaultValues.plant_id = this.props.optimizer_beef_forecast.grinder.uid;
      defaultValues.output_products = this.props.optimizer_beef_forecast.forecast.output_product_uid;
      defaultValues.quantity = this.props.optimizer_beef_forecast.forecast.quantity;
      defaultValues.production_date = this.props.optimizer_beef_forecast.forecast.date;
      defaultValues.min_50s = output_product_name.min_50s;
      defaultValues.min_fresh = output_product_name.min_fresh;
      defaultValues.cl = output_product_name.cl;
      defaultValues.expected_yield = '10%';
    } else {
      searchTerms.forEach(function (element, index, array) {
        const thisTerm = getSampleData(element.title);
        defaultValues[element.slug] = thisTerm;
        if (element.type === 'number') {
          defaultValues[element.slug] = thisTerm ? parseFloat(thisTerm) : thisTerm;
        }
      });
    }

    this.state = {
      allResponses: [{}, {}, {}, {}, {}, {}], // four output columns
      error: false,
      searchAreas: [defaultValues, {}, {}, {}, {}, {}], // placeholder and five empty columns
      currentSelections: [],
      loading: false,
      orderLoading: [],
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchBeefInputProducts(this.props.token));
    this.props.dispatch(fetchBeefOutputProducts(this.props.token));
    this.props.dispatch(fetchGrinders(this.props.token));
    this.props.dispatch(fetchPackerPlants(this.props.token));
    if (!this.props.optimizer_data) {
      this.props.dispatch(fetchOptimizerData(this.props.token));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.optimizer_bsm_orders, nextProps.optimizer_bsm_orders)) {
      this.setState({ orderLoading: [] });
    }
  }

  optimise() {
    const { allResponses, searchAreas } = this.state;

    const that = this;
    const requestArray = [];
    this.setState({
      error: false,
    });
    searchAreas.forEach(
      function (element, index, array) {
        // Check for errors
        const checkError = checkForErrors(element);
        if (!checkError) {
          // Need to do a fetch on each set, then order them correctly when they return
          // Therefore easier to do a fetch right here and not in an action
          if (Object.keys(element).length) {
            const elementCopy = JSON.parse(JSON.stringify(element));
            elementCopy.grinder_uid = element.plant_id.toString();
            elementCopy.quantity = parseFloat(element.quantity);
            // elementCopy['quantity'] = element['quantity']*(parseFloat(elementCopy['expected_yield'].replace('%',''))/100);
            elementCopy.estimated_yield = parseInt(element.expected_yield.replace('%', '')) / 100;
            delete elementCopy.expected_yield;
            delete elementCopy.plant_id;
            delete elementCopy.production_date;
            delete elementCopy.claim;
            delete elementCopy.cl;
            this.props.optimizer_data.output_products.forEach(function (output) {
              if (output.id === parseInt(elementCopy.output_products)) {
                elementCopy.output_product = output;
                elementCopy.output_product.min_fresh = parseInt(element.min_fresh);
              }
            });
            // delete elementCopy['output_products'];
            // delete elementCopy['claim'];
            const coveredInputs = [];
            const beefInputs = [];
            this.props.optimizer_data.packer_input_product_inventory_items.map(input_product => {
              const thisBeefInput = JSON.parse(JSON.stringify(input_product));
              if (coveredInputs.indexOf(thisBeefInput.uid) === -1) {
                coveredInputs.push(thisBeefInput.uid);
                this.props.input_products.map(input => {
                  if (input.uid === input_product.input_product_uid) {
                    thisBeefInput.input_product = input;
                  }
                });
                if (typeof thisBeefInput !== 'undefined' && thisBeefInput) {
                  beefInputs.push(thisBeefInput);
                }
              }
            });
            const transportArray = [];
            this.props.optimizer_data.transport_costs.map(transport => {
              transport.miles = 100;
              transportArray.push(transport);
            });
            // let header = new Headers({"Content-Type":"application/json", "Authorization":"Token "+'xxxx',"Account-ID":"1"});
            that.setState({ loading: true });
            const header = new Headers({
              'Content-Type': 'application/json',
              Authorization: `Token ${this.props.token}`,
            });
            const newData = {
              desired_output_products: [elementCopy],
              solver_packer_offers: beefInputs,
              transport_costs: transportArray,
            };
            fetch(`${Constants.URL}optimiser/solve`, {
              method: 'POST',
              headers: header,
              dataType: 'json',
              body: JSON.stringify(newData),
            })
              .then(response => response.json())
              .then(json => {
                if (json.title) {
                  that.setState({
                    error: `${json.title}: ${JSON.stringify(json.description)}`,
                    loading: false,
                  });
                } else {
                  const currentResponse = that.state.allResponses;
                  currentResponse[index] = json;
                  that.setState({
                    allResponses: currentResponse,
                    loading: false,
                  });
                }
              })
              .catch(error => {
                that.setState({ error });
              });
          }
        } else {
          this.setState({ error: checkError });
        }
      }.bind(this)
    );
  }

  excelExport() {
    const { allResponses } = this.state;
    // Data has to be converted into arrays of arrays
    const worksheetName = 'Beef Optimizer';
    const data = [
      [
        'Purchase Order ID',
        'Output Product ID',
        'Grinder ID',
        'Order Date',
        'Order Time',
        'Output Quantity',
        'Total Product Cost',
        'Total Delivery Cost',
        'Total Delivered Product Cost',
        'Total Product Cost per Unit',
        'Total Delivery Cost per Unit',
        'Total Delivered Product Cost per Unit',
        'Purchase Order Line Item ID',
        'Packer Product Inventory Item ID',
        'Product ID',
        'Packer ID',
        'Quantity',
        'Product Cost',
        'Delivery Cost',
        'Delivered Product Cost',
        'Product Cost per Unit',
        'Delivery Cost per Unit',
        'Delivered Product Cost per Unit',
      ],
    ];
    this.props.optimizer_bsm_orders.forEach(
      function (element, index, array) {
        if (typeof element.lines !== 'undefined') {
          const grinders = this.props.optimizer_data.grinders.map(grinder => {
            if (this.state.searchAreas[index].plant_id === grinder.uid) {
              return grinder;
            }
          });
          const beefOutput = this.props.output_products.map(output => {
            if (this.state.searchAreas[index].output_products === output.uid) {
              return output;
            }
          });
          element.lines.forEach(
            function (element2, index2, array2) {
              let beefInput = false;
              this.props.input_products.forEach(input => {
                if (element2.input_product_uid === input.uid) {
                  beefInput = input;
                }
              });
              let freshText = 'Frozen';
              if (beefInput.fresh) {
                freshText = 'Fresh';
              }
              let thisPacker = false;
              this.props.packer_plants.forEach(packer_plant => {
                if (element2.packer_plant_id === packer_plant.id) {
                  thisPacker = packer_plant;
                }
              });
              let thisGrinder = false;
              this.props.grinders.forEach(grinder => {
                if (element2.grinder_uid === grinder.uid) {
                  thisGrinder = grinder;
                }
              });
              let inputProductInventoryItem = false;
              this.props.optimizer_data.packer_input_product_inventory_items.forEach(invItem => {
                if (element2.packer_input_product_inventory_item_uid === invItem.uid) {
                  inputProductInventoryItem = invItem;
                }
              });
              let thisBeefOutput = false;
              this.props.output_products.forEach(output => {
                if (element2.output_product_uid === output.id) {
                  thisBeefOutput = output;
                }
              });
              const thisRow = [
                this.props.optimizer_bsm_orders[index].uid,
                thisBeefOutput.uid,
                thisGrinder.uid,
                this.props.optimizer_bsm_orders[index].order_date,
                this.props.optimizer_bsm_orders[index].order_time,
                this.props.optimizer_bsm_orders[index].purchased_quantity,
                this.props.optimizer_bsm_orders[index].ingredient_costs,
                this.props.optimizer_bsm_orders[index].transport_costs,
                this.props.optimizer_bsm_orders[index].all_in_costs,
                this.props.optimizer_bsm_orders[index].ingredient_costs_per_purchased_unit,
                this.props.optimizer_bsm_orders[index].transport_costs_per_purchased_unit,
                this.props.optimizer_bsm_orders[index].all_in_costs_per_purchased_unit,
                element2.uid,
                inputProductInventoryItem.uid,
                beefInput.uid,
                thisPacker.uid,
                element2.quantity,
                element2.ingredient_cost,
                element2.transport_cost,
                element2.all_in_cost,
                element2.ingredient_cost_per_unit,
                element2.transport_cost_per_unit,
                element2.all_in_cost_per_unit,
              ];
              data.push(thisRow);
            }.bind(this)
          );
        }
      }.bind(this)
    );
    const excelWorkbook = new Workbook();

    const excelWorksheet = sheetFromArrayOfArrays(data);
    excelWorkbook.SheetNames.push(worksheetName);
    excelWorkbook.Sheets[worksheetName] = excelWorksheet;
    const aboutWorkbook = window.XLSX.write(excelWorkbook, {
      bookType: 'xlsx',
      bookSST: true,
      type: 'binary',
    });
    saveAs(new Blob([s2ab(aboutWorkbook)], { type: 'application/octet-stream' }), 'Beef Optimizer.xlsx');
  }

  setPostValue(arrayNumber, termSlug, event) {
    const { searchAreas } = this.state;
    let newSearchAreas = [];
    // Check if it's a beef product, if so, we need to adjust the disabled inputs
    if (termSlug === 'output_products') {
      newSearchAreas = JSON.parse(JSON.stringify(searchAreas));
      this.props.output_products.map(output_product => {
        if (output_product.uid === parseInt(event.target.value)) {
          newSearchAreas[arrayNumber].output_products = output_product.uid;
          newSearchAreas[arrayNumber].uid = output_product.name;
          newSearchAreas[arrayNumber].cl = output_product.cl;
          newSearchAreas[arrayNumber].min_50s = output_product.min_50s;
          newSearchAreas[arrayNumber].min_fresh = output_product.min_fresh;
          newSearchAreas[arrayNumber][termSlug] = event.target.value;
        }
      });
    } else {
      searchAreas.forEach(
        function (element, index, array) {
          if (index === arrayNumber) {
            searchTerms.forEach(
              function (element2, index2, array2) {
                if (element2.slug === termSlug) {
                  if (element2.type === 'number') {
                    if (!isNaN(parseFloat(event.target.value))) {
                      element[termSlug] = parseFloat(event.target.value);
                    }
                  } else if (element2.type === 'slider') {
                    let thisBeefOutput = false;
                    this.props.output_products.forEach(function (output_product) {
                      if (output_product.uid === parseInt(element.output_products)) {
                        thisBeefOutput = output_product;
                      }
                    });
                    let value = event;
                    if (value < thisBeefOutput.min_fresh) {
                      value = thisBeefOutput.min_fresh;
                    }
                    element[termSlug] = parseFloat(value);
                  } else if (element2.type === 'datepicker') {
                    if (event) {
                      element[termSlug] = event.format('YYYY-MM-DD');
                    } else {
                      element[termSlug] = '';
                    }
                  } else {
                    element[termSlug] = event.target.value;
                  }
                }
              }.bind(this)
            );
          }
          newSearchAreas.push(element);
        }.bind(this)
      );
    }
    this.setState({ searchAreas: newSearchAreas });
  }

  changeAfterOptimizer(topIndex, index, type, e) {
    const { allResponses } = this.state;
    const responsesCopy = JSON.parse(JSON.stringify(allResponses));
    if (type === 'price') {
      responsesCopy[topIndex].beef_products[0].ingredients[index].beef_input[type] = e.target.value.replace('$', '');
    } else {
      responsesCopy[topIndex].beef_products[0].ingredients[index][type] = e.target.value.replace('$', '');
    }
    this.setState({
      allResponses: responsesCopy,
    });
  }

  finalize(order, index, e) {
    const { allResponses, searchAreas } = this.state;
    // Only send first for test for now
    if (Object.keys(order).length) {
      const newOrder = {};
      newOrder.output_product_uid = order.output_products;
      // newOrder['grinder_id'] = order.plant_id;
      newOrder.production_date = order.production_date;
      newOrder.production_quantity = order.quantity;
      newOrder.estimated_yield = parseFloat(order.expected_yield.replace('%', ''));
      newOrder.lines = [];
      allResponses[index].lines.forEach(function (line_item, index2) {
        // here, loop over and get from
        // var packerInputProductInvItem = false;
        // this.props.optimizer_data.packer_input_product_inventory_items.map(input_product => {
        //   if(parseInt(line_item.beef_input.uid)===input_product.id) {
        //     packerInputProductInvItem = input_product;
        //   }
        // });
        newOrder.lines.push({
          packer_input_product_inventory_item_uid: line_item.packer_input_product_inventory_item_uid,
          grinder_uid: line_item.grinder_uid,
          packer_plant_id: line_item.packer_plant_id,
          input_product_uid: line_item.input_product_uid,
          quantity: line_item.quantity,
          ingredient_cost_per_unit: line_item.all_in_cost_per_unit,
          transport_cost_per_unit: line_item.transport_cost_per_unit,
          transport_provider: '',
        });
      });
      this.props.dispatch(createOrder(newOrder, index, this.props.token));
    }
  }

  sendOrder(index) {
    const thisID = this.props.optimizer_bsm_orders[index].id;
    this.props.dispatch(sendOrder(thisID, index, this.props.token));
    const loading = JSON.parse(JSON.stringify(this.state.orderLoading));
    loading.push(index);
    this.setState({
      orderLoading: loading,
    });
  }

  reset() {
    const defaultValues = {};
    searchTerms.forEach(function (element, index, array) {
      const thisTerm = getSampleData(element.title);
      defaultValues[element.slug] = thisTerm;
      if (element.type === 'number') {
        defaultValues[element.slug] = thisTerm ? parseFloat(thisTerm) : thisTerm;
      }
    });

    this.props.dispatch(clearOrders());

    this.setState({
      searchAreas: [defaultValues, {}, {}, {}, {}, {}],
      allResponses: [{}, {}, {}, {}, {}, {}],
    });
  }

  render() {
    let responseNumber = 0;
    let beefProductNumber = [];
    if (typeof this.state.allResponses !== 'undefined') {
      this.state.allResponses.forEach(function (element, index, array) {
        if (typeof element.proposed_orders !== 'undefined') {
          responseNumber += 1;
          if (beefProductNumber.length < element.proposed_orders.length) {
            beefProductNumber = element.proposed_orders;
          }
        }
      });
    }

    return (
      <div className="m-portlet__body">
        {Object.keys(this.props.optimizer_data).length ? (
          <div style={{ maxWidth: '100%', overflowX: 'scroll', padding: '20px' }}>
            <div style={{ width: '1700px' }}>
              <div className="noPrint">
                <div className="m-section">
                  <span className="m-section__sub">
                    Enter the requirements of Beef Product(s) you wish to produce, then Submit to find the least cost
                    way to purchase and deliver the required inputs.
                  </span>
                </div>
                <div className="tab-content" style={{ marginBottom: '50px' }}>
                  <div className="input-columns column-0">
                    {searchTerms.map((term, i) => {
                      if (term.title !== 'Claim') {
                        let style = {
                          marginBottom: '10px',
                          borderColor: '#FFF',
                        };
                        if (term.title === 'Minimum % Fresh Beef') {
                          style = {
                            marginBottom: '10px',
                            borderColor: '#FFF',
                            marginTop: '25px',
                          };
                        }
                        return (
                          <div style={style} key={`term-${i}`} className="form-control">
                            {term.title}
                          </div>
                        );
                      }
                    })}
                  </div>
                  {this.state.searchAreas.map((columnValues, i) => {
                    return (
                      <div key={`input-columns-${i}`} className="input-columns">
                        {searchTerms.map((term, k) => {
                          let existingTerm = '';
                          if (typeof columnValues[term.slug] !== 'undefined' && columnValues[term.slug] !== null) {
                            existingTerm = columnValues[term.slug];
                          }
                          if (term.type === 'select') {
                            return (
                              <select
                                onChange={this.setPostValue.bind(this, i, term.slug)}
                                key={`term-${k}`}
                                className="form-control input-info"
                                value={existingTerm}
                              >
                                <option value="">(none selected)</option>
                                {this.props[term.slug].map((option, l) => {
                                  const optionName =
                                    term.slug === 'output_products' ? `${option.name} (${option.uid})` : option.name;
                                  return (
                                    <option key={`option-${l}`} value={option.id}>
                                      {optionName}
                                    </option>
                                  );
                                })}
                              </select>
                            );
                          }
                          if (term.type === 'slider') {
                            // get current min fresh of this beef output that's been selected
                            const sliderAmount = (
                              <div className="slider-amount">
                                {isNaN(parseFloat(existingTerm)) ? (
                                  <input type="text" value={0} disabled />
                                ) : (
                                  <input type="text" value={parseFloat(existingTerm)} disabled />
                                )}
                              </div>
                            );
                            if (!isNaN(parseFloat(columnValues.output_products)) && existingTerm !== '') {
                              let min_fresh_this_product = false;
                              this.props.output_products.map(output_product => {
                                if (output_product.uid === parseFloat(columnValues.output_products)) {
                                  min_fresh_this_product = output_product.min_fresh;
                                }
                              });
                              return (
                                <div className="slider-container-optimizer" key={`term-${k}`}>
                                  <div className="slider">
                                    <Slider
                                      onChange={this.setPostValue.bind(this, i, term.slug)}
                                      min={0}
                                      max={100}
                                      value={parseFloat(existingTerm)}
                                    />
                                  </div>
                                  {sliderAmount}
                                </div>
                              );
                            }
                            return (
                              <div className="slider-container-optimizer" key={`term-${k}`}>
                                <div className="slider">
                                  <Slider disabled />
                                </div>
                                {sliderAmount}
                              </div>
                            );
                          }
                          if (term.type === 'datepicker') {
                            const productionDateInputProps = {
                              placeholder: 'Production date',
                              className: 'form-control input-info',
                            };
                            return (
                              <Datetime
                                inputProps={productionDateInputProps}
                                closeOnSelect
                                minDate={new Date(moment().subtract(1, 'day').format('YYYY/MM/DD'))}
                                value={existingTerm}
                                onChange={this.setPostValue.bind(this, i, term.slug)}
                                key={`term-${k}`}
                                timeFormat={false}
                                dateFormat="YYYY-MM-DD"
                              />
                            );
                          }
                          if (term.slug !== 'claim') {
                            let placeholder = term.title;
                            if (term.title === 'Name') {
                              placeholder = 'Beef Product Name';
                            }
                            return (
                              <input
                                disabled={!term.enabled}
                                onChange={this.setPostValue.bind(this, i, term.slug)}
                                key={`term-${k}`}
                                type="text"
                                className="form-control input-info"
                                value={existingTerm}
                                placeholder={placeholder}
                              />
                            );
                          }
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
              <button
                className="btn btn-info noPrint"
                onClick={this.optimise.bind(this)}
                style={{
                  width: '50%',
                  marginTop: '-50px',
                  position: 'absolute',
                }}
              >
                OPTIMISE
              </button>
              <button
                className="btn btn-primary noPrint"
                onClick={this.reset.bind(this)}
                style={{
                  width: '10%',
                  marginTop: '-50px',
                  marginLeft: '55%',
                  position: 'absolute',
                }}
              >
                RESET
              </button>
              <div>
                {this.state.loading ? <img src="img/loader.gif" alt="loading" /> : false}
                {this.state.error ? <div className="alert alert-danger">{this.state.error.toString()}</div> : false}
                {responseNumber > 0 ? (
                  <div className="second-row yesPrint">
                    <div>
                      <div className="input-columns column-0">
                        <h3>Summary</h3>
                        <strong>To create this Product:</strong>
                        <ul>
                          <li>
                            <strong>Total Cost of Raw Material</strong>
                          </li>
                          {/* <li><strong>Total Cost of Transport</strong></li> */}
                          <li>
                            <strong>Total Cost (Raw Material + Transport)</strong>
                          </li>
                          <li style={{ color: '#d67155' }}>
                            <strong>Cost per Produced Unit</strong>
                          </li>
                        </ul>
                      </div>
                      {this.state.allResponses.map((thisResponse, i) => {
                        if (typeof thisResponse.summary !== 'undefined') {
                          return (
                            <div key={`beef-summary-${i}`} className="input-columns output-columns">
                              <strong>&nbsp;</strong>
                              <ul>
                                <li>
                                  <strong>${addCommas(Math.round(thisResponse.summary.ingredient_costs))}</strong>
                                </li>
                                <li>
                                  <strong>${addCommas(Math.round(thisResponse.summary.all_in_costs))}</strong>
                                </li>
                                <li style={{ color: '#d67155' }}>
                                  <strong>${thisResponse.summary.all_in_costs_per_unit}</strong>
                                </li>
                              </ul>
                            </div>
                          );
                        }
                      })}
                      <div style={{ clear: 'both' }} />
                      <hr />
                    </div>
                    <div className="block-column">
                      <h3>Products</h3>
                      {beefProductNumber.map((number, k) => {
                        return (
                          <div key={`specific-keys-${k}`} style={{ marginTop: '20px' }}>
                            <div className="input-columns column-0">
                              <strong>
                                Product {k + 1}
                                {}:
                              </strong>
                              <ul>
                                <li>Supplier Name</li>
                                <li>Supplier Location</li>
                                <li>Establishment #</li>
                                <li>Product</li>
                                <li>Estimated Product CL</li>
                                <li>Raw Material Cost per Unit</li>
                                <li>
                                  <strong>Least Cost per Unit (incl. Transport)</strong>
                                </li>
                                <li>Source</li>
                                <li>Fresh/Frozen</li>
                                <li>Quantity</li>
                                <li>Transport Cost per Unit</li>
                                <li>Transport Provider</li>
                                <li>
                                  <strong>Total Product Cost</strong>
                                </li>
                              </ul>
                            </div>
                            {this.state.allResponses.map((thisResponse, p) => {
                              if (typeof thisResponse.proposed_orders !== 'undefined') {
                                if (typeof thisResponse.proposed_orders[k] !== 'undefined') {
                                  const isEditable = Object.keys(this.props.optimizer_bsm_orders[p]).length === 0;

                                  let thisGrinder = false;
                                  this.props.optimizer_data.grinders.forEach(function (grinder) {
                                    if (grinder.uid === thisResponse.proposed_orders[k].grinder_uid) {
                                      thisGrinder = grinder;
                                    }
                                  });

                                  let thisPacker = false;
                                  this.props.optimizer_data.packer_plants.forEach(function (packer_plant) {
                                    if (packer_plant.id === thisResponse.proposed_orders[k].packer_plant_id) {
                                      thisPacker = packer_plant;
                                    }
                                  });

                                  let thisInputProduct = false;
                                  this.props.input_products.forEach(function (input) {
                                    if (input.uid === thisResponse.proposed_orders[k].input_product_uid) {
                                      thisInputProduct = input;
                                    }
                                  });

                                  return (
                                    <div style={{ display: 'inline' }} key={`specific-beef-${p}`}>
                                      <div className="input-columns output-columns">
                                        <strong>&nbsp;</strong>
                                        <ul>
                                          <li>
                                            {thisPacker.name.length > 20
                                              ? `${thisPacker.name.substring(0, 15)}...`
                                              : thisPacker.name}
                                          </li>
                                          <li>{thisPacker.timezone}</li>
                                          <li>{thisPacker.uid}</li>
                                          <li style={{ fontSize: '10px' }}>{thisInputProduct.name}</li>
                                          <li>{thisInputProduct.cl}</li>
                                          <li>
                                            {isEditable ? (
                                              <input
                                                type="text"
                                                className="form-control"
                                                onChange={this.changeAfterOptimizer.bind(this, p, k, 'price')}
                                                value={`$${thisResponse.proposed_orders[k].price_per_unit}`}
                                              />
                                            ) : (
                                              `$${thisResponse.proposed_orders[k].price_per_unit}`
                                            )}
                                          </li>
                                          <li>
                                            <strong>${thisResponse.proposed_orders[k].price_per_unit}</strong>
                                          </li>
                                          <li>{thisInputProduct.country}</li>
                                          <li>{thisInputProduct.fresh ? 'Fresh' : 'Frozen'}</li>
                                          <li>
                                            {isEditable ? (
                                              <input
                                                type="text"
                                                className="form-control"
                                                onChange={this.changeAfterOptimizer.bind(this, p, k, 'quantity')}
                                                value={thisResponse.proposed_orders[k].quantity}
                                              />
                                            ) : (
                                              thisResponse.proposed_orders[k].quantity
                                            )}
                                          </li>
                                          <li>
                                            {isEditable ? (
                                              <input
                                                type="text"
                                                className="form-control"
                                                onChange={this.changeAfterOptimizer.bind(
                                                  this,
                                                  p,
                                                  k,
                                                  'transport_cost_per_unit'
                                                )}
                                                value={`$${thisResponse.proposed_orders[k].transport_cost_per_unit}`}
                                              />
                                            ) : (
                                              `$${thisResponse.proposed_orders[k].transport_cost_per_unit}`
                                            )}
                                          </li>
                                          <li>
                                            {isEditable ? (
                                              <input type="text" className="form-control" placeholder="NA" />
                                            ) : (
                                              'NA'
                                            )}
                                          </li>
                                          <li>
                                            <strong>
                                              ${addCommas(Math.round(thisResponse.proposed_orders[k].all_in_cost))}
                                            </strong>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  );
                                }
                                return (
                                  <div style={{ display: 'inline' }} key={`specific-beef-${p}`}>
                                    <div className="input-columns output-columns">
                                      <p style={{ color: 'white' }}>alot of cool stuff</p>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                            <div style={{ clear: 'both' }} />
                          </div>
                        );
                      })}
                      <div className="input-columns column-0" />
                      {this.props.optimizer_bsm_orders.map((order, i) => {
                        if (Object.keys(this.state.allResponses[i]).length) {
                          if (Object.keys(order).length === 0) {
                            return false;
                          }
                          const loadingGraphicOrButton =
                            this.state.orderLoading.length && this.state.orderLoading.indexOf(i) > -1 && !order.sent ? (
                              <img style={{ marginLeft: '10px' }} src="img/loader.gif" alt="loading" />
                            ) : (
                              <div
                                onClick={this.sendOrder.bind(this, i)}
                                style={{
                                  marginTop: '10px',
                                  marginLeft: '10px',
                                }}
                                className="btn btn-info noPrint"
                              >
                                Submit Order
                              </div>
                            );
                          return (
                            <div className="input-columns output-columns" key={`buttons-${i}`}>
                              <hr />
                              <div
                                onClick={() => window.print()}
                                style={{ marginTop: '10px' }}
                                className="btn btn-info noPrint"
                              >
                                Print Results
                              </div>
                              <div
                                onClick={this.excelExport.bind(this)}
                                style={{
                                  marginTop: '10px',
                                  marginLeft: '10px',
                                }}
                                className="btn btn-info noPrint"
                              >
                                Export to Excel
                              </div>
                              {order.sent ? (
                                <button
                                  style={{
                                    marginTop: '10px',
                                    marginLeft: '10px',
                                  }}
                                  className="btn btn-info noPrint"
                                  disabled
                                >
                                  Submitted!
                                </button>
                              ) : (
                                loadingGraphicOrButton
                              )}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                ) : (
                  false
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="loading-graphic">
            <img src="img/loader.gif" alt="loading" />
          </div>
        )}
      </div>
    );
  }
}

export default Optimizer;
