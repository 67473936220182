import React, { Component } from 'react';
import { addEntity, editEntity, deleteEntity } from '../../../slices/masterData/entityManagerSlice';
import { Box, Text, VStack, Flex } from '@chakra-ui/react';
import FieldRenderChakra from './FieldRender';

const NestedFieldChakra = ({ errors, field, referencedEntities, value }) => {
  const fieldName = field.name;
  const elements = (
    <>
      <Box width="100%" marginTop="40px !important">
        <Text as="p" fontSize="15px" fontWeight="bold" color="accent.one.default">
          {field.displayName}
        </Text>
      </Box>
      {referencedEntities[field.referenceEntityType].entityDefinition.fields.map(referencedField => {
        return (
          <FieldRenderChakra
            key={referencedField.name}
            errors={errors}
            field={referencedField}
            referencedEntities={referencedEntities}
            prefix={fieldName}
            value={value ? value[referencedField.name] : ''}
          />
        );
      })}
    </>
  );
  return elements;
};
export default NestedFieldChakra;
