import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router';
// import ThemePanel from '../../theme_panel/ThemePanel';
import DynamicSidebarMenu from '@/containers/layout/DynamicSidebarMenu';
import { useDispatch } from 'react-redux';

const ClassicLayoutContainer = ({ children }) => {
  const dispatch = useDispatch();

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <DynamicSidebarMenu dispatch={dispatch} />
        <div id="main-body" className="main-body">
          {/* <ThemePanel /> */}
          <Outlet />
          {children}
        </div>
      </div>
    </div>
  );
};

ClassicLayoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ClassicLayoutContainer;
