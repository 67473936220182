import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router';
import { useDispatch } from 'react-redux';
import NextGenHeader from '../headers/NextGenHeader';
import './NextGenLayoutContainer.scss';
// import ThemePanel from '../../theme_panel/ThemePanel';
import DynamicSidebarMenu from '@/containers/layout/DynamicSidebarMenu';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const NextGenLayoutContainer = props => {
  const { children } = props;
  const dispatch = useDispatch();
  const path_no_query_string = window.location.pathname.split('?')[0];

  return (
    <div className="next-gen-layout-container">
      <NextGenHeader {...props} />
      <div className="app-content">
        <DynamicSidebarMenu dispatch={dispatch} url={path_no_query_string} />
        <div className="nav-spacer" />
        <div className="main-content">
          {/* <ThemePanel /> */}
          <Outlet />
          {children}
        </div>
      </div>
    </div>
  );
};

NextGenLayoutContainer.propTypes = propTypes;

export default NextGenLayoutContainer;
