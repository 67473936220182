// eslint-disable-next-line import/no-cycle
import PoStrategy from '../../../containers/basic/PoStrategy';

const schema = [
  {
    name: 'grinder',
    displayName: 'Grinder',
    systemIdField: 'uid',
    displayIdField: 'name',
    path: '/grinders',
    tabGroup: { key: 'grinders', label: 'Grinders' },
    deletable: false,
    autoUid: true,
    fields: [
      {
        name: 'uid',
        displayName: 'UID',
        tooltip: 'Provide the Unique ID to create the Grinder',
        dataType: 'shorttext',
        required: false,
        systemIdentifier: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'Provide the name of the Grinder',
        dataType: 'shorttext',
        required: true,
      },
      {
        name: 'po_series_id',
        displayName: 'Name',
        dataType: 'hidden',
      },
      {
        name: 'product_type',
        displayName: 'Product Type',
        tooltip: 'Select the Product type of the Grinder, either Beef or Pork.',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'product_types',
        required: true,
      },
      {
        name: 'po_strategy',
        displayName: 'Po Strategy',
        tooltip: 'Select Manual, Sequence, or Custom to define the PO strategy of the Grinder.',
        dataType: 'component',
        component: PoStrategy,
        propName: 'strategy',
        required: true,
        includeInAddNew: true,
      },
      {
        name: 'purchasing_office',
        displayName: 'Purchasing Office',
        tooltip: 'Select the Purchasing Office associated with the Grinder.',
        dataType: 'reference',
        referenceType: 'multi-select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'business_office',
      },
      {
        name: 'notification_emails',
        displayName: 'Notification Emails',
        tooltip: 'Provide the Email address to receive notifications for the Grinder.',
        dataType: 'text',
        hideList: true,
        required: false,
      },
      {
        name: 'destination_country',
        displayName: 'Destination Country',
        tooltip: 'Select the Destination country where the Grinder is located.',
        dataType: 'simple-reference',
        referenceEntityType: 'country',
        required: true,
      },
      {
        name: 'preferred_unit_id',
        displayName: 'Default Sell UOM',
        tooltip: 'Provide the Default Sell Unit of Measure (UOM) of the Grinder.',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'unit',
      },
      {
        name: 'default_sell_incoterms',
        displayName: 'Default Sell Incoterms',
        tooltip: 'Provide the default Sell incoterms of the Grinder',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'incoterms',
      },
      {
        name: 'default_sell_currency',
        displayName: 'Default Sell Currency',
        tooltip: 'Provide the Sell of default buy currency of the Grinder',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'currency',
      },
      {
        name: 'default_port_id',
        displayName: 'Default Port',
        tooltip: 'Select the by default Port for the Grinder',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'port',
      },
      {
        name: 'default_coldstore_id',
        displayName: 'Default Coldstore',
        tooltip: 'Select the by default Coldstore for the Grinder',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'coldstore',
      },
      {
        name: 'consignee_details',
        displayName: 'Consignee',
        tooltip: 'Provide the Consignee (Buyer) of the Grinder',
        dataType: 'longtext',
        hideList: true,
      },
      {
        name: 'main_notify_party_details',
        displayName: 'Main Notify Party',
        tooltip: 'Provide the Notify Party responsible after the consignee.',
        dataType: 'longtext',
        hideList: true,
      },
      {
        name: 'secondary_notify_party_details',
        displayName: 'Secondary Notify Party',
        tooltip: 'Provide the Second Notify Party responsible after the Main Notify Party.',
        dataType: 'longtext',
        hideList: true,
      },
      {
        name: 'organisation_ids',
        displayName: 'Organisation',
        tooltip: 'Select the Organisation associated with the Grinder.',
        dataType: 'reference',
        referenceType: 'multi-select',
        referenceEntityType: 'organisation',
        required: true,
      },
      {
        name: 'invoicing',
        displayName: 'Invoicing',
        dataType: 'sectionText',
      },
      {
        name: 'invoice_payment_due_days',
        displayName: 'Invoice Payment Due Days',
        tooltip: 'Specify the number of days until the Invoice Payment is due.',
        dataType: 'integer',
      },
      {
        name: 'invoice_send_days',
        displayName: 'Invoice Send Days',
        tooltip: 'Specify the number of days to send the invoice.',
        dataType: 'integer',
        hideList: true,
      },
      {
        name: 'invoice_date_type',
        displayName: 'Invoice Date Type',
        tooltip: 'Select the Invoice Date Type (ETD, ETA, Delivery Date).',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'invoice_date_type',
        hideList: true,
      },
      {
        name: 'invoice_due_date_type',
        displayName: 'Invoice Due Date Type',
        tooltip: 'Select the Invoice Due Date Type (ETD, ETA, Delivery Date).',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'invoice_date_type',
        hideList: true,
      },
      {
        name: 'invoice_weight_type',
        displayName: 'Invoice Weight Type',
        tooltip: 'Select the Invoice Weight Type (Shipped, Received, Amended, PO, Remaining).',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'invoice_weight_type',
        hideList: true,
      },
      {
        name: 'invoice_flow',
        displayName: 'Invoice Flow',
        tooltip: 'Select the Invoice Flow (Prior, Final).',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'invoice_flow',
        hideList: true,
      },
      {
        name: 'invoice_email_strategy',
        displayName: 'Invoice Email Strategy',
        tooltip: 'Choose the Invoice Email Strategy (Separate, Batch).',
        dataType: 'text',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'invoice_email_strategy',
        required: true,
        hideList: true,
      },
      {
        name: 'company_name',
        displayName: 'Invoice Company Name',
        tooltip: 'Provide the Company Name for the Invoice.',
        dataType: 'text',
        hideList: true,
      },
      {
        name: 'is_unfunded',
        displayName: 'Is Unfunded',
        tooltip: 'Select whether the Invoice is Unfunded by choosing Yes or No.',
        dataType: 'boolean',
        required: false,
      },
      {
        name: 'address',
        displayName: 'Address',
        tooltip: 'Indicate whether the Grinder is delisted by selecting Yes or No.',
        dataType: 'reference',
        referenceType: 'nested',
        referenceEntityType: 'address_port',
      },
      {
        name: 'lat',
        displayName: 'Lat',
        dataType: 'number',
        hideList: true,
      },
      {
        name: 'lon',
        displayName: 'Lon',
        dataType: 'number',
        hideList: true,
      },
      {
        name: 'timezone',
        displayName: 'Timezone',
        dataType: 'shorttext',
        hideList: true,
        required: false,
      },
      {
        name: 'contacts',
        displayName: 'Contacts',
        dataType: 'reference',
        referenceType: 'multi-nested',
        referenceEntityType: 'contact',
      },
      {
        name: 'delisted',
        displayName: 'Is Delisted',
        tooltip: 'Indicate whether the Grinder is delisted by selecting Yes or No.',
        dataType: 'boolean',
        hideList: true,
        required: true,
      },
    ],
  },
];
export default schema;
