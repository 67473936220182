import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AuthGate from '../../containers/auth/AuthGate';
import './NextGenSidebarMenu.scss';
import NextGenSidebarGroup from './NextGenSidebarGroup';
import { connect } from 'react-redux';
import { NAV_OPEN_STAGES, closeNav, openNavMax, openNavMin } from '../../actions/actions_navigation';
import { Box, IconButton } from '@chakra-ui/react';
import { ReactComponent as SeparateIconSVG } from '../../assets/separate.svg';
import { iff } from '../../helpers';
import withRouter from '@/components/utilities/withRouter';

const NAV_OPEN_DELAY_MS = 500;

const propTypes = {
  routeConfig: PropTypes.shape({
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        authConfig: PropTypes.shape({
          // Define the specific shape of authConfig here
          isAuthenticated: PropTypes.bool,
          userRole: PropTypes.string,
        }),
      })
    ),
  }),
  navigation: PropTypes.shape({
    openStatus: PropTypes.oneOf(Object.values(NAV_OPEN_STAGES)).isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const NextGenSidebarMenu = ({ routeConfig, dispatch, navigation }) => {
  const [timer, setTimer] = useState(null);
  // const breakpoints = useBreakpoint();

  // const [isMobileScreen] = useMediaQuery(`(max-width: ${get(breakpoints, 'asObject.lg', '62em')})`);
  const isMobileScreen = false;

  const { openStatus } = navigation;

  const checkIfOpen = () => {
    if (isMobileScreen) {
      return openStatus === NAV_OPEN_STAGES.max_opened;
    }

    return openStatus === NAV_OPEN_STAGES.max_opened || openStatus === NAV_OPEN_STAGES.min_opened;
  };

  return (
    <Box
      className="next-gen-nav"
      onMouseEnter={() => {
        if (isMobileScreen) return;
        document.body.classList.add('noscroll');
        const currentTimer = setTimeout(() => {
          dispatch(openNavMax());
        }, NAV_OPEN_DELAY_MS);
        setTimer(currentTimer);
      }}
      onMouseLeave={() => {
        if (isMobileScreen) return;
        dispatch(closeNav());
        document.body.classList.remove('noscroll');
        if (timer) {
          clearTimeout(timer);
        }
      }}
    >
      <Box
        className={`nav-ribbon ${checkIfOpen() ? 'open' : 'closed'}`}
        {...(isMobileScreen &&
          openStatus === NAV_OPEN_STAGES.closed && {
            width: 0,
            paddingX: '0px !important',
          })}
      >
        {routeConfig.groups.map(group => (
          <AuthGate {...group.authConfig} key={group.name}>
            <NextGenSidebarGroup group={group} open={checkIfOpen()} isMobile={isMobileScreen} />
          </AuthGate>
        ))}
        <div style={{ flex: 1 }} />
      </Box>
      {isMobileScreen && (
        <IconButton
          position="fixed"
          background="nav.main"
          _hover={{
            background: 'nav.main',
          }}
          minWidth="28px"
          height="28px"
          left={iff(
            openStatus === NAV_OPEN_STAGES.closed,
            '-28px',
            openStatus === NAV_OPEN_STAGES.max_opened ? '284px' : '62px'
          )}
          zIndex="100"
          transition="left .1s linear"
          borderWidth="1px"
          onClick={() => {
            if (openStatus === NAV_OPEN_STAGES.max_opened) dispatch(openNavMin());
            else dispatch(openNavMax());
          }}
        >
          <SeparateIconSVG />
        </IconButton>
      )}
    </Box>
  );
};

NextGenSidebarMenu.propTypes = propTypes;

const mapStateToProps = state => {
  return {
    navigation: state.navigation,
  };
};

export default connect(mapStateToProps)(withRouter(NextGenSidebarMenu));
