import React from 'react';
import { Box, SimpleGrid, Grid, GridItem, Text } from '@chakra-ui/react';
import { FieldArray } from 'formik';
import get from 'lodash/get';
import { isEmpty } from 'lodash';

import Card from '../core/Card';
import Button from '../core/Button';
import { DatePicker, Input, Select, Switch, Counter, SimpleText } from '../core/formik';
import FieldEditWarning from '../field/FieldEditWarning';
import { AccordionItem, AccordionItemContent, AccordionItemTrigger, AccordionRoot } from '../ui/accordion';
import { Checkbox } from '@/components/ui/checkbox';

function renderField(field, context, indices) {
  const { fieldType, name, isConnected, ...rest } = field;
  const { values } = context;

  const fieldProps = {
    ...rest,
    indices,
    name,
    values,
  };

  const fieldName = typeof name === 'function' ? name(indices) : name;
  const key = fieldName || field.key;

  switch (fieldType) {
    case 'select':
      return (
        <Select
          key={key}
          {...fieldProps}
          isClearable={!isEmpty(fieldProps.value)}
          menuStyles={{
            zIndex: 10,
          }}
        />
      );
    case 'switch':
      return <Switch key={key} {...fieldProps} />;
    case 'checkbox':
      return <Checkbox key={key} {...fieldProps} />;
    case 'date':
      return <DatePicker key={key} {...fieldProps} />;
    case 'simpletext':
      return <SimpleText key={key} {...fieldProps} />;
    case 'counter':
      return <Counter key={key} {...fieldProps} />;
    case 'input':
    default:
      return <Input key={key} {...fieldProps} />;
  }
}

function renderNode(config, context, indices) {
  const { children, ...node } = config;
  const { renderComponent, isHidden, ...props } = node;
  const { fieldType, name, key } = props;
  const { values } = context;
  const fieldName = typeof name === 'function' ? name(indices) : name;
  const keyValue = typeof key === 'function' ? key(indices) : key;

  const value = get(values, fieldName);
  const optionsKey = node.optionsKey || node.name;

  // Default to node options. Check context if options are stored there via key.
  const nodeOptions = get(node, 'options') || get(context, `options.${optionsKey}Options`) || [];

  const nodeProps = {
    ...props,
    ...(fieldType === 'select' && { options: nodeOptions }),
    value,
  };

  // Hidden fields are skipped
  const shouldSkipComponent = typeof isHidden === 'function' ? isHidden(config, context, indices) : isHidden || false;

  if (shouldSkipComponent) return '';

  // Single field:
  if (fieldType) {
    // For fields with an onChange or onBlur handler, we want to
    // go ahead and pass along form context to allow for the control
    // of sister fields.
    // We use the spread operator syntax so that if these handlers do not exist in nodeProps,
    // we do not add introduce them
    const fieldProps = {
      ...nodeProps,
      ...(nodeProps.onChange && {
        onChange: (_fieldName, _fieldValue, setFieldValue) =>
          nodeProps.onChange(_fieldName, _fieldValue, setFieldValue, context, indices),
      }),
      ...(nodeProps.onBlur && {
        onBlur: (_fieldName, _fieldValue, setFieldValue) =>
          nodeProps.onBlur(_fieldName, _fieldValue, setFieldValue, context, indices),
      }),
    };
    return renderField(fieldProps, context, indices);
  }

  // Component can contain children:
  if (renderComponent) {
    return renderComponent(nodeProps, children, context, indices);
  }
  // Default component is a Box if no renderComponent() method or fieldType specified
  const index = get(indices, '[0]') || 0;

  return (
    <Box mb="15px" key={keyValue || fieldName || `box-${index}`} {...props}>
      {children && children.map(child => renderNode(child, context, indices))}
    </Box>
  );
}

export function renderChildren(children, context, indices) {
  return children.map(child => renderNode(child, context, indices));
}

export function renderCard(node, children, context, indices) {
  const { headerText, headerComponent, key, ...rest } = node;
  const text = typeof headerText === 'function' ? headerText(indices) : headerText;
  const index = get(indices, '[0]') || 0;
  return (
    <Card
      {...rest}
      mx={node.mx || '50px'}
      my={node.my || '20px'}
      pb={node.pb || node.paddingBottom || '30px'}
      px={node.px || '27px'}
      key={key || `card-${index}`}
      borderColor={rest.borderColor || 'gray.400'}
      {...(text && { text })}
      {...(headerComponent && { headerComponent: headerComponent && renderNode(headerComponent, context, indices) })}
    >
      {renderChildren(children, context, indices)}
    </Card>
  );
}

export function renderGrid(gridNode, children, context, indices) {
  const { key } = gridNode;
  const keyText = typeof key === 'function' ? key(indices) : key || '';
  const index = get(indices, '[0]') || 0;
  return (
    <Grid gap={4} key={keyText || `grid-${index}`} {...gridNode}>
      {children.map(child => {
        const { colSpan, rowSpan, colStart, ...node } = child;
        const fieldName = typeof node.name === 'function' ? node.name(indices) : node.name;

        // Skip grid item if isHidden function exists, and evaluates to true
        if (node.isHidden) {
          const shouldSkipComponent =
            typeof node.isHidden === 'function' ? node.isHidden(node, context, indices) : node.isHidden || false;
          if (shouldSkipComponent) return '';
        }

        return (
          <GridItem
            key={child.key || fieldName || `grid-item-${index}`}
            {...{
              colSpan: colSpan || 2,
              colStart: colStart || undefined,
              rowSpan,
              flexDirection: node.flexDirection || node.direction,
              display: node.display || node.d,
              justifyContent: node.justifyContent || node.justify,
            }}
          >
            {renderNode(node, context, indices)}
          </GridItem>
        );
      })}
    </Grid>
  );
}

export function renderSimpleGrid(gridProps, children, context, indices) {
  const index = get(indices, `[0]`) || 0;
  return (
    <SimpleGrid key={`simple-grid-${index}`} {...{ columns: 2, spacing: 10, ...gridProps }}>
      {renderChildren(children, context, indices)}
    </SimpleGrid>
  );
}

export function renderButton(props, children, context, indices) {
  const { text, onClick, key, ...rest } = props;
  const keyText = typeof key === 'function' ? key(indices) : key;
  const index = get(indices, `[0]`) || 0;
  return (
    <Button
      {...{
        key: keyText || `button-${index}`,
        _focus: { outline: 'none' },
        ...(onClick && { onClick: e => onClick(e, context, indices) }),
      }}
      {...rest}
    >
      {text}
    </Button>
  );
}

export function renderLine(props, children, context, indices) {
  const { key } = props;
  const formatted = typeof key === 'function' ? key(indices) : key;
  return (
    <Box key={formatted} mt="10px" mb="10px">
      <hr />
    </Box>
  );
}

export function renderText(props, children, context, indices) {
  const { text, ...rest } = props;
  const formatted = typeof text === 'function' ? text(indices) : text;
  return (
    <Box key={formatted} my="10px" textAlign="center">
      <Text {...rest}>{formatted}</Text>
    </Box>
  );
}
export function renderAccordionChildren(props, children, context, indices) {
  const { key } = props;
  const formatted = typeof key === 'function' ? key(indices) : key;
  return children.map(child => (
    <AccordionItem key={formatted} borderColor="transparent">
      <AccordionItemTrigger px="0px" _hover={{ background: 'transparent' }} _focus={{ outline: 'none' }}>
        <Box flex="1" textAlign="left" display="flex" justifyContent="flex-start" alignItems="center">
          <Text {...{ fontSize: '14px', fontWeight: 'bold' }}>{child.headerText || ''}</Text>
        </Box>
      </AccordionItemTrigger>
      <AccordionItemContent px="0px">{renderChildren(child.children, context, indices)}</AccordionItemContent>
    </AccordionItem>
  ));
}

export function renderAccordion(props, children, context, indices) {
  const { key } = props;
  const formatted = typeof key === 'function' ? key(indices) : key;

  return (
    <AccordionRoot collapsible defaultIndex={0} allowToggle key={`accordion-${formatted}`}>
      {renderAccordionChildren(props, children, context, indices)}
    </AccordionRoot>
  );
}

export function renderFieldArray(props, children, context, indices = []) {
  const { listName, ...rest } = props;
  const name = typeof listName === 'function' ? listName(indices) : listName;
  const itemList = get(context, `values.${name}`) || [];
  return (
    <FieldArray
      key={name}
      name={name}
      {...rest}
      render={arrayHelpers => {
        const newContext = {
          ...context,
          [`${name}ArrayHelpers`]: arrayHelpers,
        };
        return itemList.map((item, index) => renderChildren(children, newContext, [...indices, index]));
      }}
    />
  );
}

export function renderFieldEditWarning(field) {
  return <FieldEditWarning field={field} />;
}
