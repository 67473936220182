import React from 'react';
import PropTypes from 'prop-types';

import ClassicLayoutContainer from './layoutContainers/ClassicLayoutContainer';
import NextGenLayoutContainer from './layoutContainers/NextGenLayoutContainer';

const DynamicLayoutContainer = props => {
  if (props.nextGen) {
    // Clears grey background associated with old skin
    document.body.classList.remove('m-content--skin-light2');
    return <NextGenLayoutContainer {...props} />;
  }
  return <ClassicLayoutContainer {...props} />;
};

DynamicLayoutContainer.propTypes = {
  nextGen: PropTypes.bool,
  children: PropTypes.node,
};

export default DynamicLayoutContainer;
