import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Datetime from '@/components/core/DatePicker';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import { Box, HStack, Input, Textarea } from '@chakra-ui/react';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import { FieldGroup, PreambleText, VStackLayout } from '../Components/index';
import { Checkbox } from '@/components/ui/checkbox';
import { Field } from '@/components/ui/field';

const dateTimeProps = {
  closeOnSelect: true,
  timeFormat: false,
  dateFormat: 'YYYY-MM-DD',
  inputProps: {
    style: { height: '40px' },
  },
  // readOnly: true,
};
const InitialInvestigation = ({ currentStage, onUpdate, users, user }) => {
  const { values: formikValues, errors } = useFormikContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStage]);

  const completingUserId = get(formikValues, 'ncrDetails.initialInvestigation.userId');
  const willMakeClaim = get(formikValues, 'ncrDetails.willMakeClaim');
  const completingUser = users.find(user_ => user_.id === completingUserId) || user;

  return (
    <DashboardSectionTile
      title={willMakeClaim ? 'Initial Investigation' : 'Investigation'}
      padding="16px 28px 51px 18px"
      position="relative"
    >
      <VStackLayout gap="25px" margin="0 !important">
        <Box marginTop="20px">
          <PreambleText fontSize="16px" marginBottom="11px">
            Completed By:
          </PreambleText>
          <HStack gap="32px">
            <FieldGroup label="Name">
              <Input
                placeholder="Name"
                name="name"
                value={`${get(completingUser, 'firstName', '')} ${get(completingUser, 'lastName', '')}`}
                className={
                  formikValues.highlightInputError &&
                  get(errors, 'ncrDetails.initialInvestigation.user.name') &&
                  'error'
                }
                disabled
              />
            </FieldGroup>
            <FieldGroup label="Role">
              <Input
                placeholder="Role"
                name="role"
                value={get(completingUser, 'role.name', '')}
                className={
                  formikValues.highlightInputError &&
                  get(errors, 'ncrDetails.initialInvestigation.user.role.name') &&
                  'error'
                }
                disabled
              />
            </FieldGroup>
            <FieldGroup label="Email">
              <Input
                placeholder="name@company.com"
                name="email"
                value={get(completingUser, 'email', '')}
                className={
                  formikValues.highlightInputError &&
                  get(errors, 'ncrDetails.initialInvestigation.user.email') &&
                  'error'
                }
                disabled
                type="email"
              />
            </FieldGroup>
            <FieldGroup label="Phone">
              <Input
                placeholder="Phone"
                name="(555) 555-5555"
                value={get(completingUser, 'phone', '')}
                className={
                  formikValues.highlightInputError &&
                  get(errors, 'ncrDetails.initialInvestigation.user.phone') &&
                  'error'
                }
                disabled
              />
            </FieldGroup>
          </HStack>
        </Box>
        <FieldGroup label={`${willMakeClaim ? 'Initial' : ''} Investigation Date`}>
          <Datetime
            {...dateTimeProps}
            value={moment(get(formikValues, 'ncrDetails.initialInvestigation.investigationDate', null))}
            className={
              formikValues.highlightInputError &&
              get(errors, 'ncrDetails.initialInvestigation.investigationDate') &&
              'error'
            }
            onChange={value => {
              const momentDate = moment(value, 'YYYY-MM-DD', true);
              const { _isAMomentObject: isAMomentObject } = momentDate;
              onUpdate('ncrDetails.initialInvestigation.investigationDate', isAMomentObject ? momentDate.format() : value);
            }}
          />
        </FieldGroup>
        <Box marginTop="42px !important">
          <Field label={`${willMakeClaim ? 'Initial' : ''} Investigation Details`}>
            <Textarea
              rows={6}
              placeholder="Details"
              name="details"
              value={get(formikValues, 'ncrDetails.initialInvestigation.details', null)}
              className={
                formikValues.highlightInputError && get(errors, 'ncrDetails.initialInvestigation.details') && 'error'
              }
              onChange={e => onUpdate('ncrDetails.initialInvestigation.details', e.target.value)}
            />
          </Field>
        </Box>
        {willMakeClaim ? (
          <Box>
            <Checkbox
              colorScheme="actionSecondary"
              checked={get(formikValues, 'ncrDetails.initialInvestigation.isAcknowledged')}
              onChange={e => onUpdate('ncrDetails.initialInvestigation.isAcknowledged', e.target.checked)}
              className={
                formikValues.highlightInputError &&
                get(errors, 'ncrDetails.initialInvestigation.isAcknowledged') &&
                'error'
              }
            >
              I acknowledge the receipt of this NCR. This is our agreed initial response for the facility. Final
              response will be completed in required timeframe.
            </Checkbox>
          </Box>
        ) : (
          ''
        )}
      </VStackLayout>
    </DashboardSectionTile>
  );
};

InitialInvestigation.propTypes = {
  currentStage: PropTypes.number,
  onUpdate: PropTypes.func,
  onUpdateMany: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  user: PropTypes.objectOf(PropTypes.any),
};

export default InitialInvestigation;
