import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router';
import NCRForm from '../Author/NCRForm';
import { convertToCamelCase } from '../../../helpers';
import { fetchGrinderNCR, resetNCREdit } from '../../../actions/action_ncrs';
import { checkIfIsAdminUser, checkIfIsGrinderUser, checkIfIsPackerUser } from '../../../utils';
import Constants from '../../../Constants';

const NCREdit = props => {
  const { token, user, ncrs } = props;
  const dispatch = useDispatch();
  const isPackerUser = checkIfIsPackerUser(user);
  const isAdminUser = checkIfIsAdminUser(user);
  const isGrinderUser = checkIfIsGrinderUser(user);
  const isIncident = window?.location?.pathname?.startsWith('/edit-incident');
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    if (isPackerUser || isAdminUser || isGrinderUser) {
      dispatch(resetNCREdit());
      if (isIncident) {
        dispatch(fetchGrinderNCR(id, token, Constants.NCR_TYPES.INCIDENT));
      } else {
        dispatch(fetchGrinderNCR(id, token));
      }
    }
  }, [id, isPackerUser, isAdminUser, isGrinderUser, token]);

  return !isEmpty(ncrs.currentNCR) ? (
    <NCRForm isPackerUserEditing={isPackerUser} isAdminUserEditing={isAdminUser} isGrinderUserEditing={isGrinderUser} />
  ) : null;
};

NCREdit.propTypes = {
  token: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }),
  ncrs: PropTypes.shape({
    currentNCR: PropTypes.shape({}),
  }),
};

const mapStateToProps = state => {
  const result = {
    user: state.user,
    token: state.user.token,
    ncrs: state.ncrs,
  };
  return convertToCamelCase(result);
};

export default connect(mapStateToProps)(NCREdit);
