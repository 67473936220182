import React from 'react';
import { Box, IconButton, Text } from '@chakra-ui/react';
import { Field as FormikField } from 'formik';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import DateField from './DateField';
import { IoInformationCircleOutline } from 'react-icons/io5';
import MultiNestedField from './MultiNestedField';
import NestedField from './NestedField';
import ReferenceSelectField from './ReferenceSelectField';
import SimpleReferenceSelectField from './SimpleReferenceSelectField';
import { Tooltip } from '@/components/ui/tooltip';
import { Alert } from '@/components/ui/alert';
import { Field } from '@/components/ui/field';

export const FieldErrorMessage = ({ errors, fieldName }) => {
  const error = get(errors, fieldName);
  if (!error) return false;
  return (
    <Box width="234px">
      <Alert status="error" title={error} />
    </Box>
  );
};

FieldErrorMessage.propTypes = {
  errors: PropTypes.shape({}),
  fieldName: PropTypes.string,
};

FieldErrorMessage.propTypes = {
  errors: PropTypes.shape({}),
  fieldName: PropTypes.string,
};

const FieldRender = ({ errors, field, referencedEntities, thisEntity, prefix = null, value = null, setSignatureUpload }) => {
  let elements = [];
  const fieldName = prefix ? `${prefix}.${field.name}` : field.name;
  switch (field.referenceType) {
    case 'multi-select':
    case 'select':
      elements = (
        <Box key={fieldName}>
          <Field
            htmlFor={fieldName}
            label={
              <>
                {field.displayName}&nbsp;
                {field.tooltip && (
                  <Tooltip content={field.tooltip} positioning={{ placement: 'right-end' }}>
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            }
          >
            <FormikField
              as={ReferenceSelectField}
              name={fieldName}
              SubType={field.SubType}
              containerStyles={{
                padding: '0px',
              }}
              controlStyles={{
                maxHeight: '225px',
                width: '234px',
              }}
              referencedEntity={referencedEntities[field.referenceEntityType]}
              referenceEntityType={field.referenceEntityType}
              referenceEntitySubType={field.referenceEntitySubType}
              referenceType={field.referenceType}
            />
            <FieldErrorMessage errors={errors} fieldName={fieldName} />
          </Field>
        </Box>
      );
      break;
    case 'nested':
      elements = (
        <NestedField
          errors={errors}
          field={field}
          referencedEntities={referencedEntities}
          prefix={fieldName}
          value={value}
        />
      );
      break;
    case 'multi-nested':
      elements = (
        <MultiNestedField
          errors={errors}
          field={field}
          referencedEntities={referencedEntities}
          thisEntity={thisEntity}
          prefix={fieldName}
        />
      );
      break;
    default:
      switch (field.dataType) {
        case 'simple-reference':
          {
            const options = referencedEntities[field.referenceEntityType].entities.map(item => {
              return field.code ? { label: item.name, value: item.code } : { label: item.name, value: item.name };
            });
            elements = (
              <Box key={fieldName}>
                <Field
                  htmlFor={fieldName}
                  label={
                    <>
                      {field.displayName}&nbsp;
                      {field.tooltip && (
                        <Tooltip content={field.tooltip} positioning={{ placement: 'right-end' }}>
                          <IconButton
                            width="14px"
                            height="14px"
                            padding="0"
                            minW="auto"
                            borderRadius="50%"
                            color="#878787"
                            variant="unstyled"
                          >
                            <IoInformationCircleOutline size="14px" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  }
                >
                  <FormikField
                    as={SimpleReferenceSelectField}
                    fieldName={fieldName}
                    options={options}
                    name={fieldName}
                    id={fieldName}
                  />
                  <FieldErrorMessage errors={errors} fieldName={fieldName} />
                </Field>
              </Box>
            );
          }
          break;
        case 'simple-select':
          elements = (
            <Box key={fieldName}>
              <Field
                htmlFor={fieldName}
                label={
                  <>
                    {field.displayName}&nbsp;
                    {field.tooltip && (
                      <Tooltip content={field.tooltip} positioning={{ placement: 'right-end' }}>
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                }
              >
                <FormikField
                  as={SimpleReferenceSelectField}
                  fieldName={fieldName}
                  name={fieldName}
                  id={fieldName}
                  className="form-control"
                  options={field.options}
                  controlStyles={{
                    width: '234px',
                  }}
                  isClearable={false}
                />
                <FieldErrorMessage errors={errors} fieldName={fieldName} />
              </Field>
            </Box>
          );
          break;
        case 'component':
          if (thisEntity || field.includeInAddNew) {
            const Component = field.component;
            const props = {};
            props[field.propName] = thisEntity;
            props.containerStyles = {
              padding: '0px',
            };
            props.controlStyles = {
              maxHeight: '225px',
              width: '234px',
              overflow: 'scroll',
            };
            elements = (
              <>
                <Component {...props} errors={errors} fieldName={fieldName} setSignatureUpload={setSignatureUpload}/>
                <FieldErrorMessage errors={errors} fieldName={fieldName} />
              </>
            );
          }
          break;
        case 'date':
          elements = (
            <Box key={fieldName} width="234px">
              <Field
                htmlFor={fieldName}
                label={
                  <>
                    {field.displayName}&nbsp;
                    {field.tooltip && (
                      <Tooltip content={field.tooltip} positioning={{ placement: 'right-end' }}>
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                }
              >
                <FormikField as={DateField} name={fieldName} className="form-control" />
                <FieldErrorMessage errors={errors} fieldName={fieldName} />
              </Field>
            </Box>
          );
          break;
        case 'longtext':
          elements = (
            <Box key={fieldName}>
              <Field
                htmlFor={fieldName}
                label={
                  <>
                    {field.displayName}&nbsp;
                    {field.tooltip && (
                      <Tooltip content={field.tooltip} positioning={{ placement: 'right-end' }}>
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                }
              >
                <FormikField
                  as="textarea"
                  name={fieldName}
                  id={fieldName}
                  className="form-control"
                  style={{
                    width: '234px',
                    height: '225px',
                  }}
                  rows="10"
                  value={value == null ? '' : value}
                />
                <FieldErrorMessage errors={errors} fieldName={fieldName} />
              </Field>
            </Box>
          );
          break;
        case 'sectionText':
          elements = (
            <Box width="100%" marginTop="40px !important">
              <Text as="p" fontSize="15px" fontWeight="bold" color="accent.one.default">
                {field.displayName}&nbsp;
                {field.tooltip && (
                  <Tooltip content={field.tooltip} positioning={{ placement: 'right-end' }}>
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                )}
              </Text>
            </Box>
          );
          break;
        case 'hidden':
          elements = '';
          break;
        case 'boolean':
          {
            const containBlankOption = field.containBlankOption !== undefined ? field.containBlankOption : true;
            elements =
              field.name === 'delisted' ? (
                // hide delisted since it is already in modal footer
                <Box display="none">
                  <Box marginBottom="14px">
                    <Text as="p" fontSize="15px" fontWeight="bold" color="accent.one.default">
                      Active
                    </Text>
                  </Box>
                  <Box key={fieldName}>
                    <Field
                      htmlFor={fieldName}
                      label={
                        <>
                          {field.displayName}&nbsp;
                          {field.tooltip && (
                            <Tooltip content={field.tooltip} positioning={{ placement: 'right-end' }}>
                              <IconButton
                                width="14px"
                                height="14px"
                                padding="0"
                                minW="auto"
                                borderRadius="50%"
                                color="#878787"
                                variant="unstyled"
                              >
                                <IoInformationCircleOutline size="14px" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      }
                    >
                      <FormikField
                        as="select"
                        name={fieldName}
                        id={fieldName}
                        className="form-control"
                        style={{
                          width: '234px',
                        }}
                      >
                        {containBlankOption && <option value="" />}
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                      </FormikField>
                      <FieldErrorMessage errors={errors} fieldName={fieldName} />
                    </Field>
                  </Box>
                </Box>
              ) : (
                <Box key={fieldName}>
                  <Field
                    htmlFor={fieldName}
                    label={
                      <>
                        {field.displayName}&nbsp;
                        {field.tooltip && (
                          <Tooltip content={field.tooltip} positioning={{ placement: 'right-end' }}>
                            <IconButton
                              width="14px"
                              height="14px"
                              padding="0"
                              minW="auto"
                              borderRadius="50%"
                              color="#878787"
                              variant="unstyled"
                            >
                              <IoInformationCircleOutline size="14px" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    }
                  >
                    <FormikField
                      as={SimpleReferenceSelectField}
                      fieldName={fieldName}
                      name={fieldName}
                      id={fieldName}
                      className="form-control"
                      options={[
                        {
                          label: 'No',
                          value: 'false',
                        },
                        {
                          label: 'Yes',
                          value: 'true',
                        },
                      ]}
                    />
                    <FieldErrorMessage errors={errors} fieldName={fieldName} />
                  </Field>
                </Box>
              );
          }
          break;
        default:
          elements = (
            <Box key={fieldName}>
              <Field
                htmlFor={fieldName}
                label={
                  <>
                    {field.displayName}&nbsp;
                    {field.tooltip && (
                      <Tooltip content={field.tooltip} positioning={{ placement: 'right-end' }}>
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                }
              >
                <FormikField
                  type={field.dataType}
                  name={fieldName}
                  id={fieldName}
                  className="form-control"
                  style={{
                    width: '234px',
                  }}
                  value={value == null ? '' : value}
                />
                <FieldErrorMessage errors={errors} fieldName={fieldName} />
              </Field>
            </Box>
          );
          break;
      }
      break;
  }
  return elements;
};

export default FieldRender;
