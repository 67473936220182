import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import { Tabs } from '@chakra-ui/react';
import MasterDataBaseComponent from './MasterDataBaseComponent';
import PropTypes from 'prop-types';

const UserManagementTabsComponent = ({ allEntitiesSchema, mainPath, entityType }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    if (allEntitiesSchema) {
      const activeIndex = allEntitiesSchema.findIndex(tabGroup => entityType === tabGroup.name);
      setActiveTabIndex(activeIndex);
    }
  }, [entityType, allEntitiesSchema]);

  return (
    <Tabs.Root index={activeTabIndex}>
      <Tabs.List borderBottomWidth="0px">
        {allEntitiesSchema
          .filter(def => !def.nested && !def.hidden)
          .map((def, i) => (
            <Link
              key={`list-menu-${i}`}
              to={`/${mainPath}/entity/${def.name}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Tabs.Trigger
                value={def.displayName}
                _focus={{ outline: 'none' }}
                _selected={{ borderColor: 'actionSecondary.default', borderBottomWidth: '2px' }}
                _hover={{ borderColor: 'actionSecondary.default', borderBottomWidth: '2px' }}
                fontWeight="normal"
                width="max-content"
                fontSize="14px"
                paddingX="30px"
                height="103px"
                marginRight="10px"
              >
                {def.shouldNotPluralize ? def.displayName : `${def.displayName}s`}
              </Tabs.Trigger>
            </Link>
          ))}
      </Tabs.List>
    </Tabs.Root>
  );
};

UserManagementTabsComponent.propTypes = {
  allEntitiesSchema: PropTypes.arrayOf(PropTypes.object),
  mainPath: PropTypes.string,
  entityType: PropTypes.arrayOf(PropTypes.object),
};

const UserManagementData = ({ schema }) => {
  return <MasterDataBaseComponent schema={schema} TabsComponent={UserManagementTabsComponent} />;
};

UserManagementData.propTypes = {
  schema: PropTypes.arrayOf(PropTypes.object),
};
export default UserManagementData;
