import React from 'react';
import PropTypes from 'prop-types';
import { Box, HStack, Heading, Separator, Button, VStack } from '@chakra-ui/react';
import { getOr } from '../../../helpers';
import { Tag } from '@/components/ui/tag';

const claimStatusButtonProps = {
  variant: 'unstyled',
  _focus: {
    outline: 'none',
  },
  width: '100%',
  height: 'fit-content',
};

const NCRsStatusTile = ({ summary, handleFilterChange }) => {
  return (
    <Box
      marginTop="22px"
      padding="13px 40px 23px 27px"
      boxShadow="md"
      borderRadius="15px"
      backgroundColor="card.default"
      // width="fit-content"
    >
      <Heading fontSize="16px">NCR&apos;s Status</Heading>
      <HStack separator={<Separator height="100px" width="2px" borderColor="#a1a1aa" />} gap="0px" marginTop="20px">
        {[
          {
            status: 'open',
            label: 'Open',
            filterKey: 'ncr_status',
            filterValue: 'open',
          },
          {
            status: 'closed',
            label: 'Closed',
            filterKey: 'ncr_status',
            filterValue: ['closed', 'rejected'],
          },
          {
            status: 'inProgress',
            label: 'In Progress',
            filterKey: 'ncr_status',
            filterValue: ['in_progress', 'under_review'],
          },
          {
            status: 'overdue',
            label: 'Overdue',
            filterKey: 'ncr_status',
            filterValue: 'overdue',
          },
          {
            status: 'underReview',
            label: 'Action Required',
            filterKey: 'ncr_status',
            filterValue: 'under_review',
          },
        ].map(item => {
          const count = getOr(summary.statusCounts, item.status, 0);
          let countTextColor = 'actionSecondary.default';
          if (item.status === 'overdue') countTextColor = '#DA1414';
          if (item.status === 'underReview') countTextColor = '#FFB61D';
          return (
            <Button
              key={item.status}
              variant="unstyled"
              display="flex"
              flexDirection="column"
              alignItems="center"
              padding="6px 0"
              width="151px"
              height="max-content"
              _focus={{
                outline: 'none',
              }}
              onClick={() =>
                handleFilterChange(
                  item.filterKey,
                  Array.isArray(item.filterValue) ? item.filterValue : [item.filterValue],
                  true
                )
              }
            >
              <Heading fontSize="28px" color={countTextColor}>
                {count}
              </Heading>
              <Heading fontSize="16px" fontWeight="500">
                {item.label}
              </Heading>
            </Button>
          );
        })}
        <VStack gap="7.5px" marginLeft="25px">
          <Button
            {...claimStatusButtonProps}
            onClick={() => handleFilterChange('priority', ['major', 'serious'], true)}
          >
            <HStack justifyContent="space-between">
              <Heading fontSize="12px">Claims with Value</Heading>
              <Tag colorPalette="red">{getOr(summary, 'withClaim', 0)}</Tag>
            </HStack>
          </Button>
          <Button {...claimStatusButtonProps} onClick={() => handleFilterChange('priority', ['minor'], true)}>
            <HStack justifyContent="space-between">
              <Heading fontSize="12px">Claims without Value</Heading>
              <Tag colorPalette="red">{getOr(summary, 'withoutClaim', 0)}</Tag>
            </HStack>
          </Button>
        </VStack>
      </HStack>
    </Box>
  );
};

NCRsStatusTile.propTypes = {
  summary: PropTypes.shape({
    statusCounts: PropTypes.objectOf(PropTypes.number),
    withClaim: PropTypes.number,
    withoutClaim: PropTypes.number,
  }),
  handleFilterChange: PropTypes.func,
};

export default NCRsStatusTile;
