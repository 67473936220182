import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Button, Center, Flex, Heading, HStack, IconButton, Text, Textarea, VStack } from '@chakra-ui/react';
import { MdOutlineArrowBack } from 'react-icons/md';
import { ReactComponent as ChatBubbleAccentIconSVG } from '../../../assets/chat-bubble-outline-accent.svg';
import { ReactComponent as SendIconSVG } from '../../../assets/send.svg';
import { ReactComponent as RefreshIconSVG } from '../../../assets/refresh-icon.svg';
import { checkIfIsAdminUser, checkIfIsPackerUser } from '../../../utils';
import HorizontalNavigationBand from '../../core/HorizontalNavigationBand';
// import { invertColor } from '../../../theme/utils';
import { get, groupBy } from 'lodash';
import {
  fetchNCRChatChannelMessages,
  fetchNCRChatChannels,
  fetchNCRChatUnreadMessages,
  resetNCRChatChannelMessages,
  sendNCRChatMessage,
} from '../../../actions/action_ncr_chat';
import { convertToCamelCase } from '../../../helpers';
import { connect } from 'react-redux';
import {
  DrawerBackdrop,
  DrawerCloseTrigger,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerRoot,
  DrawerBody,
} from '@/components/ui/drawer';
import { Avatar } from '@/components/ui/avatar';

const ChatListItem = ({ unreadCount, onClick, groupType, mostRecentMessage }) => {
  const lastMessageTimestamp = get(mostRecentMessage, 'modified', null);
  const lastSenderFullName = `${get(mostRecentMessage, 'user.firstName', '')} ${get(
    mostRecentMessage,
    'user.lastName',
    ''
  )}`;

  return (
    <Box onClick={onClick} cursor="pointer" paddingBottom="30px" borderBottomWidth="1px">
      <HorizontalNavigationBand justifyContent="flex-start" fontSize="16px" paddingX="18px" height="50px">
        <Text fontWeight="700" textTransform="uppercase">
          {groupType}
        </Text>
        {/* &nbsp;
        <Text fontWeight="500">{orgName}</Text> */}
      </HorizontalNavigationBand>
      <Flex marginTop="31px">
        <Avatar name={lastSenderFullName} height="62px" width="62px" />
        <Box marginLeft="11px">
          <Text fontSize="16px" fontWeight="500">
            {lastSenderFullName}
          </Text>
          <Text marginTop="11px" wordBreak="break-word">
            {get(mostRecentMessage, 'message')}
          </Text>
        </Box>
        <Flex flexDirection="column" alignItems="flex-end" minWidth="65px" marginLeft="auto">
          <Text color={unreadCount && 'accent.one.default'} fontWeight="500" whiteSpace="nowrap">
            {lastMessageTimestamp
              ? moment(lastMessageTimestamp).format(
                  moment(lastMessageTimestamp).isSame(moment(), 'day') ? 'hh:mm a' : 'DD MMM YYYY'
                )
              : ''}
          </Text>
          {unreadCount && (
            <span
              style={{
                display: 'inline-block',
                width: '37px',
                height: '37px',
                backgroundColor: 'red',
                fontWeight: 'bold',
                color: 'white',
                textAlign: 'center',
                lineHeight: '37px',
                borderRadius: '50%',
                fontSize: '14px', // Adjust the font size as needed
                marginTop: '20px',
              }}
            >
              {unreadCount}
            </span>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

ChatListItem.propTypes = {
  unreadCount: PropTypes.number,
  onClick: PropTypes.func,
  groupType: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  mostRecentMessage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    created: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
const CommunicationDrawer = ({
  isOpen,
  onClose,
  user,
  mainView,
  chatListVisible,
  dispatch,
  recordNo,
  token,
  ncrChat,
  chatChannelId,
  ncr,
  packers,
  packerPlants,
}) => {
  // const { theme } = useTheme();
  const isAdminUser = checkIfIsAdminUser(user);
  const [showChatList, setShowChatList] = useState(isAdminUser && chatListVisible);
  const [chatMessage, setChatMessage] = useState('');
  const [currentChatChannelId, setCurrentChatChannelId] = useState(null);

  const clearStateForChatMessage = () => {
    setChatMessage('');
  };

  // Sort message created time
  if (ncrChat?.messages) {
    ncrChat.messages = ncrChat?.messages.sort((a, b) => Date.parse(a.created) - Date.parse(b.created));
  }
  const chatThreadGroupedByDay = groupBy(ncrChat?.messages, item => {
    return moment(item.created).format('DD MMMM YYYY');
  });

  const lastChatBubbleRef = useRef(null);

  useEffect(() => {
    if (recordNo || showChatList) dispatch(fetchNCRChatChannels(recordNo, token));
  }, [recordNo, showChatList]);

  useEffect(() => {
    if (isOpen) setShowChatList(isAdminUser && chatListVisible);
  }, [isAdminUser, chatListVisible, isOpen]);

  useEffect(() => {
    if (recordNo || showChatList) dispatch(fetchNCRChatUnreadMessages(recordNo, token));
  }, [recordNo, showChatList]);

  useEffect(() => {
    if (ncrChat?.messages.length > 0 && lastChatBubbleRef.current) {
      lastChatBubbleRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ncrChat?.messages.length]);

  useEffect(() => {
    if (ncrChat?.channelsFetched && !showChatList && !isAdminUser) {
      const isPackerUser = checkIfIsPackerUser(user);
      const party = isPackerUser ? 'packer' : 'grinder';

      // const channel = ncrChat.channels[ncrChat.channels.length - 1];
      const channel = ncrChat?.channels.find(chat => {
        return chat.party === party;
      });
      if (channel) {
        setCurrentChatChannelId(channel.id);
        const interval = setInterval(
          function () {
            dispatch(fetchNCRChatChannelMessages(channel.id, token));
          }.bind(this),
          300000
        ); // 300,000 milliseconds = 5 minutes
        return () => clearInterval(interval); // Cleanup on component unmount
      }
    }
  }, [ncrChat?.channelsFetched]);

  // const getMessageParty = () => {
  //   if (currentChatChannelId) {
  //     return checkIfIsPackerUser(user) ? 'packer' : 'grinder';
  //   }
  //   return null;
  // };

  return (
    <DrawerRoot open={isOpen} placement="end" onClose={onClose} closeOnOverlayClick={false}>
      <DrawerBackdrop />
      <DrawerContent minWidth="723px">
        <DrawerCloseTrigger
          onClick={() => {
            dispatch(resetNCRChatChannelMessages());
            setShowChatList(true);
            onClose();
            clearStateForChatMessage();
          }}
        />
        <DrawerHeader paddingX="51px" paddingTop="37px">
          <HStack gap="10px" color="accent.one.default" borderBottomWidth="1px" paddingBottom="9px">
            <ChatBubbleAccentIconSVG />
            <Heading fontSize="16px" color="inherit">
              Latest Communication
            </Heading>
          </HStack>
          {isAdminUser && mainView === 'ncr_home' && !showChatList && (
            <Button
              variant="ghost"
              fontWeight="500"
              height="20.67px"
              padding="0"
              marginTop="11px"
              onClick={() => {
                dispatch(resetNCRChatChannelMessages());
                setShowChatList(true);
                setCurrentChatChannelId(null);
                clearStateForChatMessage();
              }}
            >
              <MdOutlineArrowBack />
              Back to Chats
            </Button>
          )}
        </DrawerHeader>

        <DrawerBody paddingX="51px" overflowY="overlay">
          <VStack align="stretch" gap="31px">
            {showChatList && ncrChat?.unreadMessagesFetched ? (
              <VStack align="stretch" gap="16px">
                {ncrChat?.channels?.map(channel => {
                  let mostRecentMessage = {};
                  if (channel?.party === 'packer') {
                    mostRecentMessage = channel?.messages.reduce((latest, message) => {
                      if (message.created > latest.created) {
                        return message;
                      }
                      return latest;
                    }, channel?.messages[0]);
                  } else {
                    mostRecentMessage = channel?.messages.reduce((latest, message) => {
                      if (message.created > latest.created) {
                        return message;
                      }
                      return latest;
                    }, channel?.messages[0]);
                  }
                  const chatUnreadMessages = ncrChat?.unreadMessages[channel.id];
                  let chatGroup = '';
                  if (isAdminUser) {
                    chatGroup = channel?.party || 'Admin';
                    if (chatGroup === 'grinder') {
                      chatGroup = `${chatGroup} : ${ncr?.grinder?.name}`;
                    } else if (chatGroup === 'packer') {
                      const packerId = get(
                        packerPlants.find(packerPlant => packerPlant.id === get(ncr, 'packerPlantId')),
                        'packerId',
                        null
                      );
                      const packerName = get(
                        packers.find(packer => packer.id === packerId),
                        'name',
                        '-'
                      );
                      chatGroup = `${chatGroup} : ${packerName}`;
                    }
                  }
                  return (
                    <ChatListItem
                      key={channel.id}
                      // theme={theme}
                      unreadCount={chatUnreadMessages}
                      onClick={() => {
                        dispatch(fetchNCRChatChannelMessages(channel.id, token));
                        setShowChatList(false);
                        setCurrentChatChannelId(channel.id);
                      }}
                      groupType={chatGroup}
                      orgName={channel.orgName || ''}
                      mostRecentMessage={mostRecentMessage}
                    />
                  );
                })}
              </VStack>
            ) : (
              ''
            )}
            {!showChatList &&
              Object.keys(chatThreadGroupedByDay).map(chatThreadGroupedByDayKey => {
                return (
                  <Box key={chatThreadGroupedByDayKey}>
                    <Center>
                      <Box backgroundColor="#F4F4F4" padding="4px 12px" borderRadius="100px" borderWidth="1px">
                        <Text fontSize="12px" fontWeight="500" color="#6E7882">
                          {chatThreadGroupedByDayKey.replace(moment().year(), '')}
                        </Text>
                      </Box>
                    </Center>
                    {chatThreadGroupedByDay[chatThreadGroupedByDayKey].map((chat, index) => {
                      const senderFullName = `${get(chat, 'user.firstName', '')} ${get(chat, 'user.lastName', '')}`;
                      const isSender = chat.user.id === user.id;
                      const alignProps = {
                        ...(isSender ? { marginLeft: 'auto !important' } : { marginRight: 'auto !important' }),
                      };

                      const chatBubbleProps = {
                        backgroundColor: isSender ? '#EBF0FF' : '#DADDDF80',
                        ...(isSender ? { marginLeft: 'auto !important' } : { marginRight: 'auto !important' }),
                      };

                      const isConsecutiveSender =
                        chatThreadGroupedByDay[chatThreadGroupedByDayKey][index - 1] &&
                        chatThreadGroupedByDay[chatThreadGroupedByDayKey][index - 1].user.id !== chat.user.id;

                      return (
                        <VStack key={chat.id} {...alignProps} gap="15px" maxWidth="400px" marginTop="17px">
                          {index === 0 || isConsecutiveSender ? (
                            <Flex {...alignProps} alignItems="center" flexDir={isSender ? 'row' : 'row-reverse'}>
                              <Text fontWeight="500" {...(!isSender && { marginLeft: '10px' })}>
                                {isSender ? 'You' : senderFullName}
                              </Text>
                              <Avatar
                                name={senderFullName}
                                width="30px"
                                height="30px"
                                {...(isSender && { marginLeft: '10px' })}
                              />
                            </Flex>
                          ) : (
                            ''
                          )}
                          <Box
                            {...chatBubbleProps}
                            padding="14px 18px"
                            borderRadius="8px"
                            ref={
                              index === chatThreadGroupedByDay[chatThreadGroupedByDayKey].length - 1
                                ? lastChatBubbleRef
                                : null
                            }
                            style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}
                          >
                            {chat.message}
                          </Box>
                          <Text fontSize="10px" fontWeight="400" color="#6E7882" {...alignProps}>
                            {moment(chat.created).format('DD MMM YYYY, hh:mm a')}
                          </Text>
                        </VStack>
                      );
                    })}
                  </Box>
                );
              })}
          </VStack>
        </DrawerBody>
        {!showChatList && (
          <DrawerFooter paddingX="51px" paddingBottom="32px">
            <Textarea
              borderRadius="10px"
              height="110px"
              rows={2}
              resize="none"
              placeholder="Write a message"
              overflowY="visible"
              value={chatMessage}
              onChange={e => setChatMessage(e.target.value)}
            />
            <IconButton
              colorScheme="actionSecondary"
              variant="outline"
              marginLeft="17px"
              border="none"
              minW="26px"
              onClick={() => {
                setChatMessage('');
                dispatch(
                  sendNCRChatMessage(
                    {
                      chat_channel_id: chatChannelId || currentChatChannelId,
                      message: chatMessage,
                      message_type: 'text',
                      user_id: user.id,
                      // party: getMessageParty(),
                    },
                    token
                  )
                );
              }}
            >
              <SendIconSVG />
            </IconButton>
            <IconButton
              colorScheme="actionSecondary"
              variant="outline"
              marginLeft="17px"
              border="none"
              minW="26px"
              title="Refresh Chat"
              onClick={() => {
                dispatch(fetchNCRChatChannelMessages(currentChatChannelId, token));
              }}
            >
              <RefreshIconSVG />
            </IconButton>
          </DrawerFooter>
        )}
      </DrawerContent>
    </DrawerRoot>
  );
};

CommunicationDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  dispatch: PropTypes.func,
  recordNo: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    // Add other user properties as needed
  }),
  mainView: PropTypes.string,
  chatListVisible: PropTypes.bool,
  ncrChat: PropTypes.shape({
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        created: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        user: PropTypes.shape({
          id: PropTypes.number.isRequired,
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired,
        }).isRequired,
      })
    ),
    channels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        party: PropTypes.string.isRequired,
        messages: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            created: PropTypes.string.isRequired,
            message: PropTypes.string.isRequired,
            user: PropTypes.shape({
              id: PropTypes.number.isRequired,
              firstName: PropTypes.string.isRequired,
              lastName: PropTypes.string.isRequired,
            }).isRequired,
          })
        ),
      })
    ),
    unreadMessages: PropTypes.objectOf(PropTypes.number),
    channelsFetched: PropTypes.bool,
    unreadMessagesFetched: PropTypes.bool,
  }),
  chatChannelId: PropTypes.number,
  ncr: PropTypes.shape({
    grinder: PropTypes.shape({
      name: PropTypes.string,
    }),
    packerPlantId: PropTypes.number,
  }),
};

const mapStateToProps = state => {
  const result = {
    user: state.user,
    token: state.user.token,
    ncrs: state.ncrs,
    ncr_chat: state.ncr_chat,
    packers: state?.entityManager?.data?.packer?.entities,
    packerPlants: state?.entityManager?.data?.packer_plant?.entities,
  };
  return convertToCamelCase(result);
};

export default connect(mapStateToProps)(CommunicationDrawer);
