import React from 'react';
import { IconButton } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { ReactComponent as MDAddIcon } from '../../assets/master-data-add-icon.svg';

function AddEntityButton({ onClick }) {
  return (
    <IconButton
      colorScheme="actionSecondary"
      height="44px"
      width="61px"
      borderRadius="6px"
      onClick={onClick}
      boxShadow="sm"
    >
      <MDAddIcon />
    </IconButton>
  );
}

AddEntityButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AddEntityButton;
