import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Group, Text, Center, Input, Box, Heading } from '@chakra-ui/react';
import { FieldGroup } from '.';
import SelectField from '../../basic/SelectField';
import { get } from 'lodash';
import { deleteNCR, resetNCRDelete } from '../../../actions/action_ncrs';
import { connect } from 'react-redux';
import { convertToCamelCase } from '../../../helpers';
import {
  DialogRoot,
  DialogContent,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogCloseTrigger,
} from '@/components/ui/dialog';

const DeleteNCRModal = ({
  onCloseModal,
  recordNo,
  handleSearch,
  dispatch,
  token,
  ncrs: { isDeleting, hasDeleted, hasError },
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [deletionDetails, setDeletionDetails] = useState({
    reason: null,
    explanation: '',
  });

  useEffect(() => {
    return () => {
      dispatch(resetNCRDelete());
    };
  }, []);

  useEffect(() => {
    if (!isDeleting && !hasError && hasDeleted) {
      onCloseModal();
      handleSearch();
    }
  }, [isDeleting, hasError, hasDeleted]);

  const deletionReasonOptions = [
    {
      label: 'NCR created in error',
      value: 'NCR created in error',
    },
    {
      label: 'Wrong Packer',
      value: 'Wrong Packer',
    },
    {
      label: 'Wrong Product',
      value: 'Wrong Product',
    },
    {
      label: 'Incident not NCR',
      value: 'Incident not NCR',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ];
  const steps = [
    {
      key: 'confirmation',
      renderContent: () => (
        <Center height="100%">
          <Text fontSize="18px" textAlign="center">
            Are you sure you want to delete this Incident/NCR? Restore will not be possible.
          </Text>
        </Center>
      ),
    },
    {
      key: 'reason',
      renderContent: () => (
        <Box>
          <FieldGroup
            label="Please select reason:"
            labelProps={{
              fontSize: '16px',
            }}
          >
            <SelectField
              placeholder="Select"
              name="deletion_reason"
              value={deletionDetails.reason}
              onChange={e => {
                setDeletionDetails(prevState => ({
                  ...prevState,
                  reason: e,
                }));
              }}
              options={deletionReasonOptions}
              optionStyles={{
                fontSize: '13px',
                fontWeight: '500',
              }}
            />
          </FieldGroup>
          <Input
            placeholder="Explain"
            width="332px"
            marginTop="40px"
            value={deletionDetails.explanation}
            onChange={e => {
              const inputValue = get(e, 'target.value', null);
              setDeletionDetails(prevState => ({
                ...prevState,
                explanation: inputValue,
              }));
            }}
          />
        </Box>
      ),
    },
  ];

  const onClickConfirm = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
      return null;
    }

    dispatch(
      deleteNCR(
        {
          recordNo,
          ...deletionDetails,
        },
        token
      )
    );
    return null;
  };
  return (
    <DialogRoot centered open onClose={onCloseModal}>
      <DialogContent minWidth="600px">
        <DialogHeader borderBottomWidth="1px">
          <Heading fontSize="20px ">Delete NCR {recordNo}</Heading>
        </DialogHeader>
        <DialogCloseTrigger asChild onClick={onCloseModal} />
        <DialogBody padding="25px 52px">
          <Box height="238px">{steps[currentStep].renderContent()}</Box>
        </DialogBody>
        <DialogFooter borderTopWidth="1px">
          <Group colorScheme="actionPrimary" marginLeft="auto" fontSize="16px" fontWeight="900" gap="9px">
            <Button
              variant="outline"
              fontSize="inherit"
              fontWeight="inherit"
              height="40px"
              width="130px"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
            <Button
              fontSize="inherit"
              fontWeight="inherit"
              height="40px"
              width="126px"
              onClick={onClickConfirm}
              disabled={currentStep === steps.length - 1 && !(deletionDetails.reason && deletionDetails.explanation)}
            >
              Confirm
            </Button>
          </Group>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

DeleteNCRModal.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  onCloseModal: PropTypes.func,
  recordNo: PropTypes.string,
  ncrs: PropTypes.objectOf(PropTypes.any),
  handleSearch: PropTypes.func,
};

const mapStateToProps = state => {
  const result = {
    token: state.user.token,
    apiRequestError: state.error,
    ncrs: state.ncrs,
  };
  return convertToCamelCase(result);
};

export default connect(mapStateToProps)(DeleteNCRModal);
