import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import ReactTooltip from 'react-tooltip';
import AuthGate from '../../containers/auth/AuthGate';
import withRouter from '@/components/utilities/withRouter';

const ClassicSidebarMenu = ({ location, routeConfig, toggleLayout }) => {
  const currentLocation = location?.pathname;

  return (
    <div id="sidebar-menu">
      <ReactTooltip />
      <div id="m_aside_left" className="m-grid__item	m-aside-left  m-aside-left--skin-dark ">
        <div
          id="m_ver_menu"
          className="m-aside-menu m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark "
          data-menu-vertical="true"
          data-menu-scrollable="false"
          data-menu-dropdown-timeout="500"
        >
          <div className="top-menu">
            <div
              className="main-logo"
              role="button"
              tabIndex="0"
              onClick={() => toggleLayout()}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  toggleLayout();
                }
              }}
            >
              <img style={{ borderRadius: '50%', border: '#aaa thin solid' }} src="img/logo-square.png" alt="logo" />
            </div>
          </div>
          {routeConfig.groups.map((group, i) => {
            return (
              <>
                <div key={i}>
                  {group.children.map((child, indx) => {
                    return (
                      <AuthGate {...child.authConfig} key={indx}>
                        <Link
                          key={indx}
                          className={`icon-item ${
                            currentLocation === child.routeConfig.path ? 'icon-item--active' : ''
                          }`}
                          to={child.routeConfig.path}
                        >
                          <div
                            key={indx}
                            data-place="right"
                            data-effect="solid"
                            data-tip={child.name}
                            style={{
                              height: 38,
                              width: 36,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {child.icon}
                          </div>
                        </Link>
                      </AuthGate>
                    );
                  })}
                </div>
                <hr />
              </>
            );
          })}
        </div>
      </div>
      <div style={{ minHeight: window.innerHeight }} className="m-grid__item	m-aside-left  m-aside-left--skin-dark" />
    </div>
  );
};

ClassicSidebarMenu.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  routeConfig: PropTypes.shape({
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        children: PropTypes.arrayOf(
          PropTypes.shape({
            authConfig: PropTypes.shape({
              roles: PropTypes.arrayOf(PropTypes.string),
              permissions: PropTypes.arrayOf(PropTypes.string),
            }),
            routeConfig: PropTypes.shape({
              path: PropTypes.string.isRequired,
            }).isRequired,
            name: PropTypes.string.isRequired,
            icon: PropTypes.node.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
  }).isRequired,
  toggleLayout: PropTypes.func.isRequired,
};

export default withRouter(ClassicSidebarMenu);
