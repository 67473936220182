import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Button } from '@chakra-ui/react';
import { connect } from 'react-redux';
import AppRoutesPageTitles from '../../AppRoutesPageTitles';
import UserAvatar from '../../../containers/basic/UserAvatar';
import { NAV_OPEN_STAGES, closeNav, openNavMin } from '../../../actions/actions_navigation';
import withRouter from '@/components/utilities/withRouter';
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from '@/components/ui/menu';

const userMenuOptions = [
  {
    name: 'My Profile',
    url: '/user-settings',
  },
  {
    name: 'Log Out',
    url: '/logout',
  },
];

const NextGenHeader = ({ dispatch, navigation, theme }) => {
  const { openStatus } = navigation;

  return (
    <>
      <div className="header-bar">
        <div className="main-logo">
          <Button
            onClick={() => {
              if (openStatus === NAV_OPEN_STAGES.max_opened || openStatus === NAV_OPEN_STAGES.min_opened) {
                dispatch(closeNav());
              } else {
                dispatch(openNavMin());
              }
            }}
            width="fit-content"
            height="fit-content"
            padding={0}
            _focus={{
              outline: 'none',
            }}
            _hover={{
              background: 'inherit',
            }}
            background="inherit"
          >
            <img style={{ maxWidth: '65px', height: 'auto' }} src={theme?.data?.home?.logo} alt="logo" />
          </Button>
        </div>
        <div className="header-bar-page-title">
          <AppRoutesPageTitles />
        </div>
        <div style={{ flex: 1 }} />
        <div className="header-bar-right-menu">
          <MenuRoot>
            <MenuTrigger asChild>
              <div style={{ cursor: 'pointer' }}>
                <UserAvatar size="lg" />
              </div>
            </MenuTrigger>
            <MenuContent>
              {userMenuOptions.map(userMenuOption => (
                <MenuItem asChild key={userMenuOption.name}>
                  <Link to={userMenuOption.url}>{userMenuOption.name}</Link>
                </MenuItem>
              ))}
            </MenuContent>
          </MenuRoot>
        </div>
      </div>
      <div className="header-spacer" />
    </>
  );
};

const mapStateToProps = state => {
  return {
    navigation: state.navigation,
    theme: state.theme,
  };
};
NextGenHeader.propTypes = {
  dispatch: PropTypes.func.isRequired,
  navigation: PropTypes.shape({
    openStatus: PropTypes.oneOf(Object.values(NAV_OPEN_STAGES)).isRequired,
  }).isRequired,
  theme: PropTypes.shape({
    data: PropTypes.shape({
      home: PropTypes.shape({
        logo: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(withRouter(NextGenHeader));
