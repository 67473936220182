import React from 'react';
import { useField } from 'formik';
import { startCase } from 'lodash';
import ModalEditorSelector from '../../utilities/ModalEditorSelector';

const ReferenceSelectField = ({
  name,
  referenceEntityType,
  referenceEntitySubType,
  referenceType,
  referencedEntity,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  let entities = referencedEntity && referencedEntity.entities;
  let { systemIdField, displayIdField } = referencedEntity.entityDefinition;
  const multi = referenceType === 'multi-select';

  const onChange = (type, options, changes) => {
    // Multi select needs formatting values.
    if (multi) {
      if (!changes) {
        return setValue([]);
      }
      let values = changes.map(change => change.value);
      if (values.includes('all')) {
        values = options.filter(({ value }) => value !== 'all').map(({ value }) => value);
      }
      return setValue(values);
    }
    return setValue(changes ? changes.value : null);
  };
  if (referenceEntitySubType) {
    // Form fields have a sub type, we will filter for it.
    entities = entities
      .filter(ent => ent.type === referenceEntitySubType)
      .map(ent => ({
        id: ent.value,
        name: startCase(ent.value),
      }));
    systemIdField = 'id';
    displayIdField = 'name';
  }

  if (!systemIdField || !displayIdField) {
    // Access List is just an array of items. Assign value and label.
    entities = entities.map(ent => ({ id: ent, name: ent }));
    systemIdField = 'id';
    displayIdField = 'name';
  }

  return (
    <ModalEditorSelector
      type={name}
      items={entities}
      valueChange={onChange}
      value={meta.value}
      valueField={systemIdField}
      labelField={displayIdField}
      multi={multi}
      className={multi ? 'restrict-height' : ''}
      multiValueStyles={{
        background: 'var(--chakra-colors-multiSelectionItem-bgColor)',
        borderWidth: '1.8px',
        borderColor: 'var(--chakra-colors-multiSelectionItem-borderColor)',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'left',
        alignItems: 'center',
        marginTop: '8px',
        height: '27px',
        minWidth: '164px',
      }}
      multiValueLabelStyles={{
        color: 'var(--chakra-colors-multiSelectionItem-color)',
        fontWeight: 'bold',
        fontSize: '14px',
        paddingLeft: 0,
      }}
      multiValueRemoveStyles={{
        color: 'var(--chakra-colors-multiSelectionItem-color)',
      }}
      {...rest}
    />
  );
};

export default ReferenceSelectField;
