import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Group, Text, Center, Box, Heading } from '@chakra-ui/react';
import { updateGrinderOnlyReleaseToPacker, resetNCRRelease } from '../../../actions/action_ncrs';
import { connect } from 'react-redux';
import { convertToCamelCase } from '../../../helpers';
import {
  DialogRoot,
  DialogContent,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogCloseTrigger,
} from '@/components/ui/dialog';

const ReleaseToPackerNCRModal = ({
  onCloseModal,
  record_no,
  release_to_packer,
  handleSearch,
  dispatch,
  token,
  ncrs: { isReleasingToPacker, hasReleasedToPacker, hasError },
}) => {
  useEffect(() => {
    return () => {
      dispatch(resetNCRRelease());
    };
  }, []);

  useEffect(() => {
    if (!isReleasingToPacker && !hasError && hasReleasedToPacker) {
      onCloseModal();
      handleSearch();
    }
  }, [isReleasingToPacker, hasError, hasReleasedToPacker]);

  const onClickConfirm = () => {
    dispatch(
      updateGrinderOnlyReleaseToPacker(
        {
          record_no,
          release_to_packer,
        },
        token
      )
    );
    return null;
  };
  return (
    <DialogRoot centered open onClose={onCloseModal}>
      <DialogContent minWidth="600px">
        <DialogHeader borderBottomWidth="1px">
          <Heading fontSize="20px ">Release NCR {record_no}</Heading>
        </DialogHeader>
        <DialogCloseTrigger asChild onClick={onCloseModal} />
        <DialogBody padding="25px 52px">
          <Box height="238px">
            <Center height="100%">
              <Text fontSize="18px" textAlign="center">
                Are you sure you want to release NCR to Packer?
              </Text>
            </Center>
          </Box>
        </DialogBody>
        <DialogFooter borderTopWidth="1px">
          <Group colorScheme="actionPrimary" marginLeft="auto" fontSize="16px" fontWeight="900" gap="9px">
            <Button
              variant="outline"
              fontSize="inherit"
              fontWeight="inherit"
              height="40px"
              width="130px"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
            <Button fontSize="inherit" fontWeight="inherit" height="40px" width="126px" onClick={onClickConfirm}>
              Confirm
            </Button>
          </Group>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

ReleaseToPackerNCRModal.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  onCloseModal: PropTypes.func,
  recordNo: PropTypes.string,
  ncrs: PropTypes.objectOf(PropTypes.any),
  handleSearch: PropTypes.func,
};

const mapStateToProps = state => {
  const result = {
    token: state.user.token,
    apiRequestError: state.error,
    ncrs: state.ncrs,
  };
  return convertToCamelCase(result);
};

export default connect(mapStateToProps)(ReleaseToPackerNCRModal);
