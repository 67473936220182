import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import AuthGate from '../../containers/auth/AuthGate';
import { useFlagMultiTenant } from '../unleash/UnleashClassFlagProvider';
import { Box, Text } from '@chakra-ui/react';
import { Tooltip } from '@/components/ui/tooltip';
import withRouter from '@/components/utilities/withRouter';

const NavItemTooltipContainer = ({ children, name }) => {
  return (
    <Tooltip
      lg={{ display: 'none' }}
      content={name}
      hasArrow
      positioning={{ placement: 'right-end' }}
      backgroundColor="actionSecondary.default"
      fontSize="12px"
      padding="10px 12px"
      fontWeight="400"
      borderRadius="4px"
      marginLeft="10px"
      sx={{
        '--popper-arrow-bg': 'colors.actionSecondary.default',
        fontFamily: 'var(--chakra-fonts-heading)',
      }}
    >
      {children}
    </Tooltip>
  );
};

NavItemTooltipContainer.propTypes = {
  name: PropTypes.string,
  children: PropTypes.elementType,
};

const propTypes = {
  group: PropTypes.shape({
    hideGroupName: PropTypes.bool,
    name: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.any]),
  }),
  open: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

const NextGenSidebarGroup = ({ group, open, location }) => {
  const [expanded, setExpanded] = useState(true);
  const [groupHeight, setGroupHeight] = useState(undefined);
  const groupEl = useRef(null);

  useEffect(() => {
    if (groupHeight === undefined) {
      setGroupHeight(groupEl.current.clientHeight);
    }
  });

  const currentLocation = location?.pathname;
  const expandedGroupHeight = expanded ? groupHeight : 0;
  return (
    <Box className={`nav-group ${expanded ? 'nav-group-expanded' : 'nav-group-collapsed'}`}>
      {!group.hideGroupName && (
        <Box
          className="nav-group-title"
          onClick={evt => {
            evt.preventDefault();
            evt.stopPropagation();
            setExpanded(!expanded);
          }}
          onKeyPress={() => {}}
          role="presentation"
        >
          {open && <Text className="nav-item__name">{group.name}</Text>}
          {open && <div style={{ flex: 1 }} />}
          {open && (
            <i
              style={{ transform: `rotate(${expanded ? 90 : 0}deg)`, transition: 'transform .1s linear' }}
              className="m-menu__link-icon fa fa-chevron-right nav-group-expand__icon"
            />
          )}
        </Box>
      )}
      <Box ref={groupEl} style={{ height: !groupHeight ? 'auto' : expandedGroupHeight }} className="nav-group-children">
        {group.children
          .filter(child => !child.excludeFromNavigation)
          .map(child => {
            let icon;

            if (!child.icon) {
              // default to something smarter
              icon = <Box className="icon-item-icon-container">&nbsp;</Box>;
            } else {
              icon = <Box className="icon-item-icon-container">{child.icon}</Box>;
            }
            return (
              <AuthGate {...child.authConfig} key={child.name}>
                {/* <NavItemTooltipContainer name={child.name}> */}
                {child.routeConfig?.isExternalNavigation ? (
                  <a
                    className="icon-item icon-item-top-level"
                    target={child.routeConfig.target}
                    href={child.routeConfig.path}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {icon}
                      <Box
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Text className="nav-item__name">{child.name}</Text>
                      </Box>
                    </Box>
                  </a>
                ) : (
                  <Link
                    className={`icon-item icon-item-top-level ${
                      currentLocation === child.routeConfig.path ? 'icon-item--active' : ''
                    } ${child.disabledFlag ? !useFlagMultiTenant(child.disabledFlag) && 'disabled-link' : ''}`}
                    to={child.routeConfig.defaultPath ? child.routeConfig.defaultPath : child.routeConfig.path}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {icon}
                      <Box
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Text className="nav-item__name">{child.name}</Text>
                      </Box>
                    </Box>
                  </Link>
                )}
                {/* </NavItemTooltipContainer> */}
                {child.children && child.children.filter(grandchild => !grandchild.excludeFromNavigation) && (
                  <Box className="icon-item-group-level-2">
                    {child.children
                      .filter(grandchild => !grandchild.excludeFromNavigation)
                      .map(grandchild => {
                        return (
                          <AuthGate {...grandchild.authConfig} key={grandchild.name}>
                            {/* <NavItemTooltipContainer name={grandchild.name}> */}
                            <Link
                              className={`icon-item icon-item-level-2 disable-link ${
                                currentLocation === grandchild.routeConfig.path ||
                                (grandchild.routeConfig.pathOptions &&
                                  grandchild.routeConfig.pathOptions.includes(currentLocation))
                                  ? 'icon-item--active'
                                  : ''
                              } ${
                                grandchild.disabledFlag
                                  ? !useFlagMultiTenant(grandchild.disabledFlag) && 'disabled-link'
                                  : ''
                              }`}
                              to={
                                grandchild.routeConfig.defaultPath
                                  ? grandchild.routeConfig.defaultPath
                                  : grandchild.routeConfig.path
                              }
                            >
                              <Box
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: '100%',
                                }}
                              >
                                {/* Improve this in the future to allow for passable component */}
                                {grandchild.icon ? (
                                  <Box className="icon-item-icon-container">{grandchild.icon}</Box>
                                ) : (
                                  <Box className="icon-item-icon-container">
                                    <span>●</span>
                                  </Box>
                                )}
                                <Box
                                  style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    flex: 1,
                                  }}
                                >
                                  <Text className="nav-item__name">{grandchild.name}</Text>
                                </Box>
                              </Box>
                            </Link>
                            {/* </NavItemTooltipContainer> */}
                          </AuthGate>
                        );
                      })}
                  </Box>
                )}
              </AuthGate>
            );
          })}
      </Box>
    </Box>
  );
};

NextGenSidebarGroup.propTypes = propTypes;

export default withRouter(NextGenSidebarGroup);
