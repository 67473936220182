import React, { useEffect, useState } from 'react';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { HistoryLogItem, VStackLayout } from '../Components/index';
import { PaginationBar } from '../../core/PaginationComponents';
import SelectField from '../../basic/SelectField';
import { convertToCamelCase } from '../../../helpers';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchNCRHistory, fetchIncidentHistory } from '../../../actions/action_ncr_history';

const HistoryTab = ({ ncrs: { currentNCR }, ncrHistory: { pagination, items }, dispatch, token, isNCR }) => {
  const [paginationState, setPaginationState] = useState({
    itemsPerPage: 10,
    currentPage: 1,
    pageCount: 8,
  });

  const handleHistorySearch = filters => {
    if (isNCR) {
      dispatch(fetchNCRHistory(filters, token));
    } else {
      dispatch(fetchIncidentHistory(filters, token))
    }
  };

  useEffect(() => {
    handleHistorySearch({ recordNo: currentNCR.recordNo });
  }, []);

  const handlePageChange = pageFilters => {
    handleHistorySearch({ recordNo: currentNCR.recordNo, ...pageFilters });
  };

  return (
    <DashboardSectionTile
      padding="2px 33px 22px 37px"
      marginTop="2px"
      position="relative"
      borderTopLeftRadius={0}
      headerFlexStyle={{
        justifyContent: 'space-between',
      }}
      minHeight="612px"
    >
      <Heading fontFamily="Poppins" fontSize="15px" color="accent.one.default" marginTop="42px">
        History
      </Heading>
      <VStackLayout gap="12px" paddingX="0" borderBottomWidth="1px" marginTop="15px !important" maxWidth="856px" fontSize="14px">
        {items.length > 0 ? (
          items.map(item => <HistoryLogItem key={item.id} {...item} />)
        ) : (
          <Text>No History Data</Text>
        )}
      </VStackLayout>
      {items.length > 0 ? ( <Flex flexDir="column" alignItems="flex-end">
        <Box>
          <SelectField
            placeholder="Show 20"
            controlStyles={{
              width: '119px',
              borderRadius: '8px',
              background: '#FFFFFF',
            }}
            defaultValue={paginationState.itemsPerPage}
            onChange={e => {
              setPaginationState({
                ...paginationState,
                itemsPerPage: e.value,
              });
            }}
            options={[
              { value: '20', label: 'Show 20' },
              { value: '30', label: 'Show 30' },
              { value: '40', label: 'Show 40' },
              { value: '50', label: 'Show 50' },
            ]}
          />
        </Box>       
        <PaginationBar
          currentPage={pagination.hasNext ? pagination?.nextPage - 1 || 1 : pagination?.pages}
          pages={pagination?.pages || 10}
          onPageChange={page =>
            handlePageChange({
              page,
            })
          }
          justifyContent="unset"
          width="fit-content"
          marginTop="16px"
        />
      </Flex>) : null}
    </DashboardSectionTile>
  );
};

HistoryTab.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string.isRequired,
  ncrHistory: PropTypes.objectOf(PropTypes.any),
  ncrs: PropTypes.objectOf(PropTypes.any),
  isNCR: PropTypes.bool
};

const mapStateToProps = state => {
  const result = {
    user: state.user,
    token: state.user.token,
    ncrs: state.ncrs,
    ncr_history: state.ncr_history,
  };
  return convertToCamelCase(result);
};

export default connect(mapStateToProps)(HistoryTab);
