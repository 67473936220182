import React, { useEffect } from 'react';
import { Button, Flex, Box, Group, Text, HStack, IconButton } from '@chakra-ui/react';
import { Form, Formik, Field as FormikField } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { IoInformationCircleOutline } from 'react-icons/io5';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  delEntity,
  patchEntity,
  postEntity,
  selectEntities,
  selectSaveError,
  selectSaveSuccess,
  setSaveSuccess,
  setSaveSuccessMessage,
  selectSaveSuccessMessage,
} from '../../slices/masterData/entityManagerSlice';
import FieldRender, { FieldErrorMessage } from './fields/FieldRender';
import SimpleReferenceSelectField from './fields/SimpleReferenceSelectField';
import { Tooltip } from '@/components/ui/tooltip';
import {
  DialogRoot,
  DialogContent,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogCloseTrigger,
} from '@/components/ui/dialog';
import { Alert } from '../ui/alert';
import { Field } from '@/components/ui/field';
import { toaster } from '@/components/ui/toaster';
import { validatePassword } from '../../utils';

const validField = field => {
  if (field.hidden && field.hidden === true) {
    return false;
  }
  return field.name && field.displayName && field.dataType;
};

const generateDefaultValues = (theseEntities, foundEntity) => {
  if (!theseEntities.entityDefinition.fields || !foundEntity) {
    return {};
  }

  const defaultValues = theseEntities.entityDefinition.fields.reduce((acc, field) => {
    const result = { ...acc };
    // Hidden field such as ID, doesn't need to be updated.
    if (field.hidden === true) return result;
    if (field.referenceType && field.referenceType === 'nested') {
      if (!foundEntity[field.name]) {
        result[field.name] = {};
      } else {
        Object.entries(foundEntity[field.name]).forEach(([key, value]) => {
          // Nested fields needs prefix.
          if (!result[field.name]) {
            result[field.name] = {};
          }
          result[field.name][key] = value;
        });
      }
    } else if (field.referenceType && field.referenceType === 'multi-nested') {
      if (!foundEntity[field.name]) {
        result[field.name] = [];
      } else {
        foundEntity[field.name].forEach((entity, index) => {
          // Nested fields needs prefix.
          Object.entries(entity).forEach(([key, value]) => {
            if (!result[field.name]) {
              result[field.name] = [];
            }
            if (!result[field.name][index]) {
              result[field.name][index] = {};
            }
            result[field.name][index][key] = value;
          });
        });
      }
    } else if (field.dataType === 'password') {
      result[field.name] = '';
    } else if (field.dataType === 'boolean') {
      result[field.name] = foundEntity[field.name] ? 'true' : 'false';
    } else {
      result[field.name] = foundEntity[field.name];
    }
    return result;
  }, {});

  return defaultValues;
};

const generateYupSchema = (entityConfig, referencedEntities) => {
  const yupShape = {};

  if (entityConfig.fields) {
    entityConfig.fields
      .filter(field => !field.hidden)
      .forEach(field => {
        let yupField;
        switch (field.dataType) {
          case 'password':
            yupField = Yup.string()
              .nullable()
              .transform(value => (value === null || value === undefined ? '' : value))
              .test('validate-password', function (value) {
                const { email } = this.parent;
                if (!value || value.trim() === '') {
                  return true;
                }
                const validationMessage = validatePassword(value, email, true);
                return validationMessage === null ? true : this.createError({ message: validationMessage });
              });
            break;
          case 'date':
            yupField = Yup.date().typeError('Invalid date');
            break;
          case 'shorttext':
            yupField = Yup.string()
              .transform(value => (value === '' ? null : value))
              .nullable()
              .max(60, 'Short text too long');
            break;
          case 'text':
            yupField = Yup.string()
              .transform(value => (value === '' ? null : value))
              .nullable();
            break;
          case 'simple-reference':
            yupField = Yup.string()
              .transform(value => (value === '' ? null : value))
              .nullable();
            break;
          case 'longtext':
            yupField = Yup.string();
            break;
          case 'integer':
            yupField = Yup.number().integer().typeError('Invalid integer value');
            break;
          case 'number':
            yupField = Yup.number().typeError('Invalid numeric value');
            break;
          case 'reference':
            if (field.referenceType === 'nested') {
              yupField = generateYupSchema(
                referencedEntities[field.referenceEntityType].entityDefinition,
                referencedEntities
              );
            } else if (field.referenceType === 'multi-nested') {
              yupField = Yup.array().of(
                generateYupSchema(
                  referencedEntities[field.referenceEntityType].entityDefinition,
                  referencedEntities
                ).nullable()
              );
            } else {
              yupField = Yup.string();
            }
            break;
          default:
            yupField = Yup.string();
            break;
        }
        if (yupField) {
          if (field.required) {
            yupField = yupField.required(`${field.displayName} field required`);
          } else {
            yupField = yupField.nullable();
          }
        }

        yupShape[field.name] = yupField;
      });
  }

  return Yup.object().shape(yupShape);
};

const EntityEditor = ({ entityDefinition, referencedEntities, activeEntityid, showEditModel, setShowEditModel }) => {
  // const toast = useToast();
  const dispatch = useDispatch();
  const allEntities = useSelector(selectEntities);
  const saveError = useSelector(selectSaveError);
  const saveSuccessMessage = useSelector(selectSaveSuccessMessage);
  const saveSuccess = useSelector(selectSaveSuccess);
  const entityType = entityDefinition.name;
  const entityDisplayName = entityDefinition.displayName;
  const theseEntities = (allEntities && allEntities[entityType]) || [];
  const thisEntity = theseEntities.entities
    ? theseEntities.entities.find(entity => entity[theseEntities.entityDefinition.systemIdField] === activeEntityid)
    : null;
  const [signatureupload, setSignatureUpload] = React.useState(false);

  const closeModel = () => {
    setShowEditModel(false);
    // Reset save error and success status.
    dispatch(setSaveSuccessMessage(null));
    dispatch(setSaveSuccess(false));
  };
  const onClear = () => {
    dispatch(setSaveSuccessMessage(null));
    dispatch(setSaveSuccess(false));
  };
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    if (entityDisplayName === 'User' && activeEntityid) {
      if (!signatureupload) {
        setSubmitting(false);
        return;
      }
    }
    const newValues = { ...values };
    // Handle deletion of Multi-Nested values.
    // Formik can't delete a field value, set it to null and remove it here.
    Object.entries(newValues).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        newValues[key] = value.filter(val => val);
      }

      if (typeof value === 'string') {
        newValues[key] = value.trim();
      }
    });
    if ('password' in newValues && (!newValues.password || newValues.password.trim() === '')) {
      delete newValues.password;
    }
    if (activeEntityid) {
      dispatch(patchEntity({ entityDef: entityDefinition, entity: newValues, entityid: activeEntityid }));
    } else {
      dispatch(postEntity({ entityDef: entityDefinition, entity: newValues })).then(() => {
        resetForm({});
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    // Close this editor model only if update is successful.
    if (saveSuccess === true) {
      closeModel();
      if (saveSuccessMessage) {
        toaster.create({
          title: saveSuccessMessage,
          status: 'success',
        });
      }
    }
  }, [saveSuccess, saveSuccessMessage]);

  return (
    <div className="modal-editor">
      {showEditModel && (
        <DialogRoot open onClose={closeModel} closeOnOverlayClick={false}>
          <DialogContent maxW="80%">
            <DialogHeader borderBottomWidth="1px" fontSize="20px">
              {activeEntityid ? `Edit` : `Create`}
              {` ${entityDisplayName}`}
            </DialogHeader>
            <DialogCloseTrigger onClick={closeModel} />
            <Formik
              initialValues={generateDefaultValues(theseEntities, thisEntity, activeEntityid, allEntities)}
              validationSchema={generateYupSchema(entityDefinition, referencedEntities)}
              onSubmit={onSubmit}
            >
              {({ errors, isSubmitting, resetForm, values }) => (
                <Form>
                  <DialogBody padding="22px">
                    <Box>
                      {saveError && (
                        <Alert status="error" mb={3}>
                          {saveError}
                        </Alert>
                      )}
                      <Flex gap="30px" flexWrap="wrap">
                        {entityDefinition.fields &&
                          entityDefinition.fields.filter(validField).map(field => {
                            return (
                              <FieldRender
                                errors={errors}
                                key={field.name}
                                field={field}
                                referencedEntities={referencedEntities}
                                thisEntity={thisEntity}
                                value={values[field.name]}
                                setSignatureUpload={setSignatureUpload}
                              />
                            );
                          })}
                      </Flex>
                    </Box>
                  </DialogBody>
                  <DialogFooter borderTopWidth="1px" marginTop="26px" padding="16px 22px">
                    {entityDefinition.fields &&
                      entityDefinition.fields.filter(validField).map(field => {
                        const fieldName = field.name;
                        // const containBlankOption =
                        //   field.containBlankOption !== undefined ? field.containBlankOption : true;
                        return (
                          !field.referenceType &&
                          field.dataType === 'boolean' &&
                          field.name === 'delisted' && (
                            <HStack key={fieldName} gap="20px">
                              <Field
                                label={
                                  <>
                                    {field.displayName}
                                    {field.tooltip && (
                                      <Tooltip content={field.tooltip} positioning={{ placement: 'right-end' }}>
                                        <IconButton
                                          width="14px"
                                          height="14px"
                                          padding="0"
                                          minW="auto"
                                          borderRadius="50%"
                                          color="#878787"
                                          variant="unstyled"
                                        >
                                          <IoInformationCircleOutline size="14px" />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </>
                                }
                                htmlFor={fieldName}
                                margin="0px"
                              >
                                <FormikField
                                  as={SimpleReferenceSelectField}
                                  fieldName={fieldName}
                                  name={fieldName}
                                  id={fieldName}
                                  className="form-control"
                                  options={[
                                    {
                                      label: 'No',
                                      value: 'false',
                                    },
                                    {
                                      label: 'Yes',
                                      value: 'true',
                                    },
                                  ]}
                                  controlStyles={{
                                    width: '150px',
                                  }}
                                  isClearable={false}
                                />
                                <FieldErrorMessage errors={errors} fieldName={fieldName} />
                              </Field>
                            </HStack>
                          )
                        );
                      })}
                    {activeEntityid && entityDefinition.deletable && entityDefinition.deletable === true ? (
                      <Button
                        type="button"
                        variant="outline"
                        colorScheme="error"
                        width="163px"
                        height="43px"
                        onClick={() => {
                          // eslint-disable-next-line no-alert
                          const confirmation = window.confirm('Are you sure you want to delete item?');
                          if (confirmation) {
                            dispatch(delEntity({ entityDef: entityDefinition, entityid: activeEntityid }));
                            closeModel();
                          }
                        }}
                      >
                        <Text as="p" fontSize="16px" fontWeight="semibold">
                          Delete
                        </Text>
                      </Button>
                    ) : (
                      false
                    )}
                    <Group marginLeft="auto">
                      <Button
                        type="button"
                        width="126px"
                        height="43px"
                        data-dismiss="modal"
                        onClick={() => {
                          resetForm(); // Reset the form fields
                          onClear(); // Clear the save success and error messages
                        }}
                      >
                        <Text as="p" fontSize="16px" fontWeight="black">
                          Clear
                        </Text>
                      </Button>
                      &nbsp;
                      <Button
                        type="submit"
                        width="126px"
                        height="43px"
                        colorScheme="actionPrimary"
                        disabled={isSubmitting}
                        marginLeft="10px"
                      >
                        <Text as="p" fontSize="16px" fontWeight="black">
                          {activeEntityid ? `Apply` : `Add`}
                        </Text>
                      </Button>
                    </Group>
                  </DialogFooter>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </DialogRoot>
      )}
    </div>
  );
};

export default EntityEditor;

EntityEditor.propTypes = {
  entityDefinition: PropTypes.shape({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        dataType: PropTypes.string.isRequired,
        hidden: PropTypes.bool,
        referenceType: PropTypes.string,
        referenceEntityType: PropTypes.string,
        required: PropTypes.bool,
        tooltip: PropTypes.string,
        deletable: PropTypes.bool,
      })
    ).isRequired,
    deletable: PropTypes.bool,
    systemIdField: PropTypes.string.isRequired,
  }).isRequired,
  referencedEntities: PropTypes.objectOf(
    PropTypes.shape({
      entityDefinition: PropTypes.shape({
        fields: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            dataType: PropTypes.string.isRequired,
            hidden: PropTypes.bool,
            referenceType: PropTypes.string,
            referenceEntityType: PropTypes.string,
            required: PropTypes.bool,
            tooltip: PropTypes.string,
          })
        ).isRequired,
      }).isRequired,
    })
  ),
  activeEntityid: PropTypes.string,
  showEditModel: PropTypes.bool,
  setShowEditModel: PropTypes.func,
};
