import React, { useEffect } from 'react';
import { Box, HStack, Input, Textarea } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import Datetime from '@/components/core/DatePicker';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import { FieldGroup, PreambleText, VStackLayout } from '../Components/index';
import { Field } from '@/components/ui/field';

const dateTimeProps = {
  closeOnSelect: true,
  timeFormat: false,
  dateFormat: 'YYYY-MM-DD',
  inputProps: {
    style: { height: '40px' },
  },
};
const FinalInvestigation = ({ currentStage, onUpdate, users, user }) => {
  const { values: formikValues, errors } = useFormikContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStage]);
  const completingUserId = get(formikValues, 'ncrDetails.finalInvestigation.userId');
  const completingUser = users.find(user_ => user_.id === completingUserId) || user;

  return (
    <DashboardSectionTile title="Final Investigation" padding="16px 28px 51px 18px" position="relative">
      <VStackLayout gap="35px" margin="0 !important">
        <Box marginTop="20px">
          <PreambleText fontSize="16px" marginBottom="11px">
            Completed By:
          </PreambleText>
          <HStack gap="32px">
            <FieldGroup label="Name">
              <Input
                placeholder="Name"
                name="name"
                value={`${get(completingUser, 'firstName', '')} ${get(completingUser, 'lastName', '')}`}
                className={
                  formikValues.highlightInputError && get(errors, 'ncrDetails.finalInvestigation.user.name') && 'error'
                }
                disabled
              />
            </FieldGroup>
            <FieldGroup label="Role">
              <Input
                placeholder="Role"
                name="role"
                value={get(completingUser, 'role.name', '')}
                className={
                  formikValues.highlightInputError &&
                  get(errors, 'ncrDetails.finalInvestigation.user.role.name') &&
                  'error'
                }
                disabled
              />
            </FieldGroup>
            <FieldGroup label="Email">
              <Input
                placeholder="name@company.com"
                name="email"
                value={get(completingUser, 'email', '')}
                className={
                  formikValues.highlightInputError && get(errors, 'ncrDetails.finalInvestigation.user.email') && 'error'
                }
                disabled
                type="email"
              />
            </FieldGroup>
            <FieldGroup label="Phone">
              <Input
                placeholder="Phone"
                name="phone"
                value={get(completingUser, 'phone', '')}
                className={
                  formikValues.highlightInputError && get(errors, 'ncrDetails.finalInvestigation.user.phone') && 'error'
                }
                disabled
              />
            </FieldGroup>
          </HStack>
        </Box>
        <FieldGroup label="Final Investigation Date">
          <Datetime
            {...dateTimeProps}
            value={moment(get(formikValues, 'ncrDetails.finalInvestigation.investigationDate', null))}
            className={
              formikValues.highlightInputError &&
              get(errors, 'ncrDetails.finalInvestigation.investigationDate') &&
              'error'
            }
            onChange={value => {
              const momentDate = moment(value, 'YYYY-MM-DD', true);
              const { _isAMomentObject: isAMomentObject } = momentDate;
              onUpdate('ncrDetails.finalInvestigation.investigationDate', isAMomentObject ? momentDate.format() : value);
            }}
          />
        </FieldGroup>
        <FieldGroup label="Root Cause Identified as:">
          <Input
            value={get(formikValues, `ncrDetails.finalInvestigation.rootCause`, null)}
            className={
              formikValues.highlightInputError && get(errors, 'ncrDetails.finalInvestigation.rootCause') && 'error'
            }
            onChange={e => onUpdate('ncrDetails.finalInvestigation.rootCause', e.target.value)}
          />
        </FieldGroup>
        <Box>
          <Field label="Corrective Actions">
            <Textarea
              rows={6}
              placeholder="Actions"
              name="correctiveAction"
              value={get(formikValues, `ncrDetails.finalInvestigation.correctiveAction`, null)}
              className={
                formikValues.highlightInputError &&
                get(errors, 'ncrDetails.finalInvestigation.correctiveAction') &&
                'error'
              }
              onChange={e => onUpdate('ncrDetails.finalInvestigation.correctiveAction', e.target.value)}
            />
          </Field>
        </Box>
        <FieldGroup label="Date Implemented">
          <Datetime
            {...dateTimeProps}
            value={moment(get(formikValues, 'ncrDetails.finalInvestigation.correctiveActionImplementedDate', null))}
            className={
              formikValues.highlightInputError &&
              get(errors, 'ncrDetails.finalInvestigation.correctiveActionImplementedDate') &&
              'error'
            }
            onChange={value => {
              const momentDate = moment(value, 'YYYY-MM-DD', true);
              const { _isAMomentObject: isAMomentObject } = momentDate;
              onUpdate(
                'ncrDetails.finalInvestigation.correctiveActionImplementedDate',
                isAMomentObject ? momentDate.format() : value
              );
            }}
          />
        </FieldGroup>
        <Box>
          <Field label="Preventive Actions">
            <Textarea
              rows={6}
              placeholder="Actions"
              name="preventiveAction"
              value={get(formikValues, `ncrDetails.finalInvestigation.preventiveAction`, null)}
              className={
                formikValues.highlightInputError &&
                get(errors, 'ncrDetails.finalInvestigation.preventiveAction') &&
                'error'
              }
              onChange={e => onUpdate('ncrDetails.finalInvestigation.preventiveAction', e.target.value)}
            />
          </Field>
        </Box>
        <FieldGroup label="Date Implemented">
          <Datetime
            {...dateTimeProps}
            value={moment(get(formikValues, 'ncrDetails.finalInvestigation.preventiveActionImplementedDate', null))}
            className={
              formikValues.highlightInputError &&
              get(errors, 'ncrDetails.finalInvestigation.preventiveActionImplementedDate') &&
              'error'
            }
            onChange={value => {
              const momentDate = moment(value, 'YYYY-MM-DD', true);
              const { _isAMomentObject: isAMomentObject } = momentDate;
              onUpdate(
                'ncrDetails.finalInvestigation.preventiveActionImplementedDate',
                isAMomentObject ? momentDate.format() : value
              );
            }}
          />
        </FieldGroup>
      </VStackLayout>
    </DashboardSectionTile>
  );
};

FinalInvestigation.propTypes = {
  currentStage: PropTypes.number,
  onUpdate: PropTypes.func,
  onUpdateMany: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  user: PropTypes.objectOf(PropTypes.any),
};

export default FinalInvestigation;
